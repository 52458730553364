export const API_BASE = process.env.REACT_APP_API_BASE
    ? process.env.REACT_APP_API_BASE
    : 'http://panfactory-dev.panakeia.ai:8002';

export const PANAKEIA_LAB_UUID = process.env.REACT_APP_LAB_UUID || '';

const baseUrl = window.location.origin;

export const AUTH_CONFIG = {
           domain: process.env.REACT_APP_DOMAIN || '',
           client_id: process.env.REACT_APP_CLIENT_ID || '',
           response_type: 'token id_token',
           audience: 'pannotator',
           redirect_uri: `${baseUrl}/callback`,
           logout_uri: `${baseUrl}`,
           scope: 'openid profile api-access',
       };

export const PREDICTION_UPDATE_INTERVAL = 1e4;

// Time to consider prediction failed if no new update were fetched from the backend.
export const PREDICTION_STATUS_UPDATE_TIMEOUT = 10 * 60;

export const SEGMENTATION_TASK_TYPES = ['high-level', 'cell-level']

export const CELL_LEVEL_SEGMENTATION_TASK_NAME = 'low-level_2'
