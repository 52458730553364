import { Annotation } from '../data/Annotation';
import { AnnotationMode } from '../data/AnnotationMode';
import { Prediction } from '../data/Prediction';
import { Slide, SYNC_STATUS } from '../data/Slide';
import { actionTypes } from './Action';

export function addAlert(subject: string, content: string, autohide = false) {
    return {
        type: actionTypes.ADD_ALERT,
        payload: {
            content,
            alertSubject: subject,
            autohide,
        },
    };
}

export function addAnnotation(slideUuid: string, annotation: Annotation) {
    return {
        type: actionTypes.ADD_ANNOTATION,
        payload: {
            slideUuid,
            annotation,
        },
    };
}

export function addAllAnnotations(annotations: {[slideUuid: string]: Annotation[]}) {
    return {
        type: actionTypes.ADD_ALL_ANNOTATIONS,
        payload: {
            annotations,
        },
    };
}

export function addAnnotations(slideUuid: string, annotations: Annotation[]) {
    return {
        type: actionTypes.ADD_ANNOTATIONS,
        payload: {
            slideUuid,
            annotations,
        },
    };
}

export function addAllProfilerPredictions(
    profilerPredictions: {[imageUuid: string]: {[taskName: string]: Prediction}},
) {
    return {
        type: actionTypes.ADD_ALL_PROFILER_PREDICTIONS,
        payload: {
            profilerPredictions,
        },
    };
}

export function addProgress(
    subject: string,
    progressUuid: string,
    percentage: number,
) {
    return {
        type: actionTypes.ADD_PROGRESS,
        payload: {
            percentage,
            progressUuid,
            progressSubject: subject,
        },
    };
}

export function addSegmentations(slideUuid: string, segmentation_predictions: Prediction[]) {
    return {
        type: actionTypes.ADD_SEGMENTATIONS,
        payload: {
            slideUuid,
            segmentation_predictions,
        },
    };
}

export function hideProgress(progressUuid: string) {
    return {
        type: actionTypes.HIDE_PROGRESS,
        payload: {
            progressUuid,
        },
    };
}

export function removeAlert(alertUuid: string) {
    return {
        type: actionTypes.REMOVE_ALERT,
        payload: {
            alertUuid,
        },
    };
}

export function setAnnotationModes(annotationModes: {[uuid: string]: AnnotationMode}) {
    return {
        type: actionTypes.SET_ANNOTATION_MODES,
        payload: {
            annotationModes,
        },
    }
}

export function setIsEditing(slideUuid: string, isEditing: boolean) {
    return {
        type: actionTypes.SET_IS_EDITING,
        payload: {
            slideUuid,
            isEditing,
        },
    };
}

export function setModels(models: any) {
    return {
        type: actionTypes.SET_MODELS,
        payload: {
            models,
        },
    };
}

export function setPagination(page: string, pagination: any) {
    return {
        type: actionTypes.SET_PAGINATION,
        payload: {
            page,
            pagination,
        },
    }
}

export function setSlide(slide: Slide) {
    return {
        type: actionTypes.SET_SLIDE,
        payload: {
            slide,
        },
    };
}

export function setSlides(slides: any) {
    return {
        type: actionTypes.SET_SLIDES,
        payload: {
            slides,
        },
    };
}

export function setSlideUpdatedTime(slideUuid: string, updatedTime: string) {
    return {
        type: actionTypes.SET_SLIDE_UPDATED_TIME,
        payload: {
            slideUuid,
            updatedTime,
        },
    };
}

export function setSlideStatus(slideUuid: string, status: SYNC_STATUS) {
    return {
        type: actionTypes.SET_SLIDE_STATUS,
        payload: {
            slideUuid,
            status,
        },
    };
}

export function setPredictionClassCounts(slideUuid: string, segmentationPredictionUuid: string,
    labelsCounts: any) {
    return {
        type: actionTypes.SET_PREDICTION_CLASS_COUNTS,
        payload: {
            slideUuid,
            segmentationPredictionUuid,
            labelsCounts,
        },
    };
}

export function setTissueRatio(slideUuid: string, segmentationPredictionUuid: string,
    tissueRatio: number) {
    return {
        type: actionTypes.SET_TISSUE_RATIO,
        payload: {
            slideUuid,
            segmentationPredictionUuid,
            tissueRatio,
        },
    }
}


export function registerPrediction(slideUuid: string, modelUuid: string) {
    return {
        type: actionTypes.REGISTER_PREDICTION,
        payload: {
            slideUuid,
            modelUuid,
        },
    }
}


export function updatePredictionProgress(predictionProgress: any) {
    return {
        type: actionTypes.UPDATE_PREDICTION_PROGRESS,
        payload: {
            predictionProgress,
        },
    };
}
