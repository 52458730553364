import React, { Component } from 'react';

import { connect } from 'react-redux';
import {
 Button, Form, Col, Row,
} from 'react-bootstrap';
import styled from 'styled-components';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
    fetchModels,
    uploadSlide,
} from '../../types/actions/AsyncActionCreators';
import { Model } from '../../types/data/Model';
import { MainState } from '../../reducers/MainState';
import { Auth0Context } from '../../infrastructure/Auth0/Auth0Provider';

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 10px;
    padding-right: 10px;
`;

const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    margin: 0 12px;
    border: solid 2px #ccc;
`;

const StyledForm = styled(Form)`
    width: 100%;
`;

const FormSection = styled.div`
    margin: 1em 0.8em;
`;

const FormSectionHeader = styled.div`
    margin: 0 0 0.8em 0;
    padding: 0.8em 0 0 0.8em;
`;

interface IUploadSlidePageProps {
    fetchModels: (authToken: string) => void;
    history?: any;
    models: Model[];
    uploadSlide: (
        authToken: string,
        name: string,
        inserted_by: string,
        scanned_date: string,
        file: string,
        model_id?: string,
    ) => void;
}

interface IUploadSlidePageState {
    file: any;
    name: string | undefined;
    scanned_date: any;
    model_id?: string;
}

class UploadSlidePage extends Component<
    IUploadSlidePageProps,
    IUploadSlidePageState
> {
    constructor(props: IUploadSlidePageProps) {
        super(props);
        this.state = {
            file: null,
            name: undefined,
            scanned_date: new Date(),
            model_id: undefined,
        };
        this.onFormChange = this.onFormChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidMount() {
        if (this.props.models.length === 0) {
            if (this.context.isAuthenticated) {
                this.context.getTokenSilently().then((authToken: string) => {
                    this.props.fetchModels(authToken);
                });
            } else {
                console.log('Not logged in while trying to fetch models.');
            }
        } else {
            this.setState({ model_id: this.props.models[0].uuid });
        }
    }

    componentDidUpdate(prevProps: IUploadSlidePageProps) {
        if (prevProps.models.length === 0 && this.props.models.length > 0) {
            this.setState({ model_id: this.props.models[0].uuid });
        }
    }

    onFormChange(field: string, value: any) {
        if (field === 'file') {
            // Set name to be file name
            this.setState({ name: value.name });
        }
        // @ts-ignore: Field is one of fields
        this.setState({ [field]: value });
    }

    handleChangeScannedDate = (scannedDate: Date) => {
        this.setState({ scanned_date: scannedDate });
    };

    handleSubmit(e: any) {
        e.preventDefault();
        const {
            model_id, name, file, scanned_date,
        } = this.state;
        if (this.context.isAuthenticated && name !== undefined && file !== null) {
            this.context.getTokenSilently().then((authToken: string) => {
                this.props.uploadSlide(
                    authToken,
                    String(name),
                    this.context.user.sub,
                    scanned_date.getTime(),
                    file,
                    model_id,
                );
                // redirect to slides table page
                const path = '/pannotator';
                this.props.history.push(path);
            });
        } else {
            console.log('Not logged in while trying to upload slide.');
        }
    }

    render() {
        const { models } = this.props;
        return (
            <Wrapper>
                <StyledForm>
                    <FormSection>
                        <FormSectionHeader>
                            <Form.Label column={false}>
                                Upload Biopsy Slide
                            </Form.Label>
                        </FormSectionHeader>
                        <Form.Group as={Row}>
                            <Form.Label md={2} column>
                                Upload File
                            </Form.Label>
                            <Col md={4}>
                                <Form.Control
                                    required
                                    accept=".svs,.tif,.scn,.tiff,.bif,.ndpi,.vms,.vmu,.mrxs,.svslide"
                                    name="file"
                                    type="file"
                                    onChange={(e: any) =>
                                        this.onFormChange(
                                            'file',
                                            e.target.files[0],
                                        )
                                    }
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label md={2} column>
                                Name
                            </Form.Label>
                            <Col md={4}>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Slide name"
                                    value={this.state.name}
                                    onChange={(e: any) =>
                                        this.onFormChange(
                                            'name',
                                            e.target.value,
                                        )
                                    }
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label md={2} column>
                                Scanned date
                            </Form.Label>
                            <Col md={4}>
                                <DatePicker
                                    selected={this.state.scanned_date}
                                    onChange={this.handleChangeScannedDate}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group controlId="slideupload.modelSelection">
                            <Form.Label md={2} column>
                                Model
                            </Form.Label>
                            <Col md={4}>
                                <Form.Control
                                    as="select"
                                    onChange={(e: any) =>
                                        this.onFormChange(
                                            'model_id',
                                            e.target.value,
                                        )
                                    }
                                    value={this.state.model_id}
                                >
                                    {models.map((model: Model) => (
                                        <option
                                            key={model.uuid}
                                            value={model.uuid}
                                        >
                                            {model.name}
                                        </option>
                                    ))}
                                </Form.Control>
                            </Col>
                        </Form.Group>
                        <ButtonWrapper>
                            <Button
                                disabled={
                                    this.state.name === undefined ||
                                    this.state.file === null
                                }
                                onClick={this.handleSubmit}
                                type="submit"
                                variant="primary"
                            >
                                Upload
                            </Button>
                        </ButtonWrapper>
                    </FormSection>
                </StyledForm>
            </Wrapper>
        );
    }
}

const mapStateToProps = (state: MainState) => ({
    models: Object.values(state.models),
});

const mapDispatchToProps = (dispatch: any) => ({
        fetchModels: (authToken: string) => dispatch(fetchModels(authToken)),
        uploadSlide: (
            authToken: string,
            name: string,
            inserted_by: string,
            scanned_date: string,
            file: any,
            model_id?: string,
        ) => dispatch(
                uploadSlide(
                    authToken,
                    name,
                    inserted_by,
                    scanned_date,
                    file,
                    model_id,
                ),
            ),
    });

UploadSlidePage.contextType = Auth0Context;

export default connect(mapStateToProps, mapDispatchToProps)(UploadSlidePage);
