import React from 'react';
import Tooltip from '@material-ui/core/Tooltip';

type LimitedWidthTextProps = {
    maxChars: number;
    text: string;
};

const LimitedWidthText = ({ maxChars, text }: LimitedWidthTextProps) => {
    const slicedText = text.slice(0, maxChars);
    const isSliced = slicedText.length < text.length;
    return isSliced ? (
        <Tooltip title={text}>
            <span>
                {slicedText}
                ...
            </span>
        </Tooltip>
    ) : (
            <span>{text}</span>
        );
};

export default LimitedWidthText;
