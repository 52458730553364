import React, { Component } from 'react'
import { Button } from 'react-bootstrap';
import { Auth0Context } from '../../infrastructure/Auth0/Auth0Provider';

interface ILoginProps {}
interface ILoginState {}

class Login extends Component<ILoginProps, ILoginState> {
    async login() {
        await this.context.loginWithRedirect();
    }

    render() {
        return (
            <div className="page">
                <div className="d-flex flex-column w-100 h-100 justify-content-center align-items-center">
                    <h3 className="p-2">
                        You need to be logged in to use PANnotator
                    </h3>
                    <Button
                        className="btn-lg"
                        type="button"
                        variant="secondary"
                        onClick={() => this.login()}
                    >
                        Login
                    </Button>
                </div>
            </div>
        );
    }
}

Login.contextType = Auth0Context;

export default Login
