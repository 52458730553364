import React from 'react';
import styled from 'styled-components';
import { Line } from "rc-progress";
import { Model } from '../../../types/data/Model';

const Wrapper = styled.div`
  position: absolute;
  bottom: 60px;
  right: 22px;
  border-radius: 6px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: baseline;
  width: 15%;
  min-width: 160px;
  background-color: rgba(187,187,187,0.5);
  padding: 12px;
  pointer-events: none;
    & > * {
        margin-right: 10px;
        pointer-events: none;
  }
`;
const Body = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column
`;
const Header = styled.strong`
  margin-right: auto;
`
const DetailToggle = styled.div`
    size: 10px;
    padding-bottom: -5px;
    color: -webkit-link;
    pointer-events: auto;
    cursor: pointer;
`;
const PredictionInfo = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 2px;
  width: 100%;
`
const PredictionName = styled.div`
  padding-bottom: 2px;
`

interface IPredictionIndicatorProps {
    predictions: {
        [model_uuid: string]: {
            status: string,
            progress: number,
            timestamp: number
        }
    },
    models: { [model_uuid: string]: Model }
}

interface IPredictionIndicatorState {
    expanded: boolean
}

// eslint-disable-next-line react/prefer-stateless-function,max-len
class PredictionIndicator extends React.Component<IPredictionIndicatorProps, IPredictionIndicatorState> {
    constructor(props: IPredictionIndicatorProps) {
        super(props);
        this.state = {
            expanded: true,
        }
    }

    render() {
        return (
            <Wrapper>
                <Header>
                    {`Predictions: ${
                        Object.keys(this.props.predictions).length
                    }`}
                </Header>
                <Body>
                    {this.state.expanded &&
                        Object.keys(this.props.predictions).map((model_uuid) => (
                            <PredictionInfo key={model_uuid}>
                                <PredictionName>
                                    {`${this.props.models[model_uuid].name}: ${this.props.predictions[model_uuid].progress}%`}
                                </PredictionName>
                                <Line
                                    percent={
                                        this.props.predictions[model_uuid]
                                            .progress
                                    }
                                    strokeWidth={4}
                                    strokeColor="#222222"
                                />
                            </PredictionInfo>
                        ))}
                    <DetailToggle
                        onClick={() => {
                            this.setState(
                                (prevState: IPredictionIndicatorState) => ({
                                    expanded: !prevState.expanded,
                                }),
                            );
                        }}
                    >
                        {this.state.expanded ? 'Collapse' : 'Expand'}
                    </DetailToggle>
                </Body>
            </Wrapper>
        );
    }
}

export default PredictionIndicator;
