import React, { Component } from 'react'
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import ClipLoader from 'react-spinners/ClipLoader';

import OpenSeadragon from '../../plugins/openseadragon-paperjs-overlay';
import '../../plugins/openseadragon-scalebar';
import { API_BASE } from '../../constants';
import { setSlideStatus } from '../../types/actions/ActionCreators';
import { fetchModels, syncSlide } from '../../types/actions/AsyncActionCreators';
import { MainState } from '../../reducers/MainState';
import { OpenseadragonWrapper } from '../OpenseadragonWrapper';
import { Auth0Context } from '../../infrastructure/Auth0/Auth0Provider';
import { Slide, SYNC_STATUS } from '../../types/data/Slide';
import { Model } from '../../types/data/Model';
import ProfilerResults from '../ProfilerResults';
import CaseTable from '../CaseTable';
import PredictionIndicator from "./SlidePage/PredictionIndicator";
import { Mask } from "../../types/data/Mask";

const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
`;
const ViewerContainer = styled.div`
  height: 100%;
  width: 100%;
  margin-bottom: auto;
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
`;

const getViewer = (authToken: string) =>
    new OpenSeadragon.Viewer({
        id: 'osd-viewer',
        prefixUrl: '//openseadragon.github.io/openseadragon/images/',
        gestureSettingsMouse: {
            clickToZoom: false,
            dblClickToZoom: true,
        },
        visibilityRatio: 1.0,
        constrainDuringPan: true,
        imageLoaderLimit: 5,
        showNavigator: true,
        navigatorPosition: 'BOTTOM_RIGHT',
        navigatorHeight: 140,
        navigatorWidth: 220,
        navigatorBackground: '#e9f1f8',
        navigatorBorderColor: 'white',
        navigatorDisplayRegionColor: '#28a745',
        showZoomControl: false,
        showHomeControl: false,
        showFullPageControl: false,
        zoomPerScroll: 1.4,
        loadTilesWithAjax: true,
        // @ts-ignore
        ajaxHeaders: {
            authorization: `Bearer ${authToken}`,
        },
    });

enum osdStatus {
    'EMPTY',
    'INITIALISING',
    'INITIALISED'
}

const getTiledImage = (slideUuid: string, authToken: string, onSuccess: (() => void)) => ({
    tileSource: `${API_BASE}/histoslide/${slideUuid}.dzi`,
    x: 0,
    y: 0,
    ajaxHeaders: {
        authorization: `Bearer ${authToken}`,
    },
    success: () => {
        onSuccess();
    },
});

interface IDispatchProps {
    fetchModels: (authToken: string) => void;
    setSlideStatus: (status: SYNC_STATUS) => void;
    syncSlide: (authToken: string, modelUuids: string[], user: string) => void;
}

interface IInheritedProps {
    match?: any;
    slide?: Slide;
    models: { [model_uuid: string]: Model };
    profilerModelUuids: string[];
}

type IProfilerPageProps = IInheritedProps & IDispatchProps

interface IProfilerPageState {
    osdStatus: osdStatus;
    maskUuid?: string;
    overlayVisible: boolean;
    // segmentation?: Annotation;
    showMask: boolean;
}

class ProfilerPage extends Component<IProfilerPageProps, IProfilerPageState> {
    viewer: any;

    constructor(props: IProfilerPageProps) {
        super(props);
        this.state = {
            osdStatus: osdStatus.EMPTY,
            maskUuid: undefined,
            overlayVisible: false,
            showMask: false,
        };
    }

    componentDidMount() {
        if (this.context.isAuthenticated) {
            this.context.getTokenSilently().then((authToken: string) => {
                this.props.syncSlide(authToken, this.props.profilerModelUuids,
                    this.context.user.sub);
                this.props.fetchModels(authToken)
            });
        }
    }

    componentDidUpdate(prevProps: IProfilerPageProps, prevState: IProfilerPageState) {
        const { slide } = this.props;
        // When initial slide has been synced, prepare viewer and display slide
        if (
            slide &&
            slide.updated &&
            this.state.osdStatus === osdStatus.EMPTY
        ) {
            this.setState({
                osdStatus: osdStatus.INITIALISING,
            });
            this.prepareOsd();
        }
        // When slide change occurs and new slide has been fetched, display it
        if (prevProps.slide && this.props.slide &&
            (prevProps.slide.uuid !== this.props.slide.uuid) &&
            this.state.osdStatus === osdStatus.INITIALISED) {
            this.changeSlide(this.props.slide.uuid);
        }
        // When slide change occurs (new route), fetch new slide
        if (
            prevProps.match.params.slideUuid &&
            (prevProps.match.params.slideUuid !==
                this.props.match.params.slideUuid)
        ) {
            if (this.context.isAuthenticated) {
                this.context.getTokenSilently().then((authToken: string) => {
                    this.props.syncSlide(authToken, this.props.profilerModelUuids,
                        this.context.user.sub);
                });
            }
        }

        if (prevState.maskUuid !== this.state.maskUuid) {
            this.reloadMask();
        }
        if (prevState.showMask && !this.state.showMask) {
            this.setState({ overlayVisible: false });
            const mask = this.viewer.world.getItemAt(1);
            if (mask !== undefined) {
                this.viewer.world.removeItem(mask);
            }
        }
    }

    addScaleBar = () => {
        if (this.props.slide && this.props.slide.mpp) {
            this.viewer.scalebar({
                minWidth: '75px',
                pixelsPerMeter: 1e6 / this.props.slide.mpp,
                color: 'black',
            });
        } else {
            // dummy hidden scalebar for now as I did not figure out how to remove it
            this.viewer.scalebar({
                minWidth: '75px',
                pixelsPerMeter: 1,
                color: 'rgba(0, 0, 0, 0)',
                fontColor: 'rgba(0, 0, 0, 0)',
            });
        }
    }

    changeSlide = (newSlideUuid: string) => {
        this.viewer.addTiledImage({
            tileSource: `${API_BASE}/histoslide/${newSlideUuid}.dzi`,
            x: 0,
            y: 0,
            success: () => {
                this.hideMask();
                const oldImage = this.viewer.world.getItemAt(0);
                this.viewer.world.removeItem(oldImage);
                this.addScaleBar();
            },
        });
    };

    changeMask = (mask: Mask) => {
        if (!this.state.overlayVisible && !this.state.showMask) {
            this.setState({
                maskUuid: mask.uuid,
                showMask: true,
            });
        } else {
            this.hideMask();
        }
    };

    hideMask = (e?: any) => {
        if (e) {
            e.preventDefault();
        }
        if (this.viewer && this.state.overlayVisible && this.state.showMask) {
            this.setState({ showMask: false, maskUuid: undefined });
        }
    };

    prepareOsd = () => {
        if (this.context.isAuthenticated) {
            this.context.getTokenSilently().then((authToken: string) => {
                this.viewer = getViewer(authToken);
                this.addScaleBar();
                this.props.setSlideStatus(SYNC_STATUS.DISPLAYED);

                const onSuccess = () => {
                };

                const tiledImageOptions = getTiledImage(
                    this.props.match.params.slideUuid,
                    authToken,
                    onSuccess,
                );

                this.viewer.addTiledImage(tiledImageOptions);
                this.setState({ osdStatus: osdStatus.INITIALISED });
            });
        }
    };

    reloadMask = () => {
        const oldMask = this.viewer && this.viewer.world.getItemAt(1);
        if (this.context.isAuthenticated) {
            this.context.getTokenSilently().then((authToken: string) => {
                let tiledImageOptions = {
                    tileSource: `${API_BASE}/histoslide/${
                        this.state.maskUuid
                    }/${String(new Date().getTime())}/mask.dzi`,
                    opacity: 0.4,
                    width: undefined,
                    x: 0,
                    y: 0,
                    ajaxHeaders: {
                        authorization: `Bearer ${authToken}`,
                    },
                    error: () => {
                        this.setState({ showMask: false });
                    },
                    success: () => {
                        this.setState({ overlayVisible: true });
                    },
                };
                if (oldMask !== undefined) {
                    const { width, x, y } = oldMask.getBounds();
                    tiledImageOptions = {
                        ...tiledImageOptions,
                        width,
                        x,
                        y,
                    };
                    this.viewer.world.removeItem(oldMask);
                }

                if (this.state.showMask) {
                    this.viewer.addTiledImage(tiledImageOptions);
                }
            });
        }
    };

    render() {
        const { models, slide } = this.props;

        return (
            <>
                <CaseTable />
                {slide && (
                    <ProfilerResults
                        changeMask={this.changeMask}
                        maskUuid={this.state.maskUuid}
                    />
                )}
                {slide ? (
                    <ViewerContainer>
                        <OpenseadragonWrapper />
                    </ViewerContainer>
                ) : (
                    <LoaderContainer>
                        <ClipLoader size={150} loading />
                    </LoaderContainer>
                )}
                <ViewerContainer />
                {slide && slide.prediction_status &&
                Object.keys(slide.prediction_status).length > 0 && (
                    <PredictionIndicator
                        predictions={slide.prediction_status}
                        models={models}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (state: MainState) => ({
    models: state.models,
    profilerModelUuids: Object.values(state.models)
        .filter((m) => m.type === 'biomarker').map((m) => m.uuid),
    slide: state.slide,
});

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
    // setSlide: () => dispatch(setSlide(ownProps.match.params.slideUuid)),
    fetchModels: (authToken: string) =>
        dispatch(fetchModels(authToken)),
    setSlideStatus: (status: SYNC_STATUS) =>
        dispatch(setSlideStatus(ownProps.match.params.slideUuid, status)),
    syncSlide: (authToken: string, modelUuids: string[], user: string) =>
        dispatch(
            syncSlide(
                authToken,
                ownProps.match.params.slideUuid,
                modelUuids,
                user,
            ),
        ),
});

ProfilerPage.contextType = Auth0Context;

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ProfilerPage),
)
