/*
    Resizable drawer for left and right drawers which may have
    a harcoded top value in the style property.
    Built on top of rc-drawer
*/

import React, { Component } from 'react';
import styled from 'styled-components';
import Drawer from 'rc-drawer';
import 'rc-drawer/assets/index.css';

const Dragger = styled.div`
    position: absolute;
    z-index: 100;
`;
const HorizontalDragger = styled(Dragger)`
    cursor: ew-resize;
    width: 3px;
    top: 0;
    bottom: 0;
`;
const VerticalDragger = styled(Dragger)`
    cursor: ns-resize;
    height: 3px;
    left: 0;
    right: 0;
`;
const LeftDragger = styled(HorizontalDragger)`
    left: 0;
`;
const RightDragger = styled(HorizontalDragger)`
    right: 0;
`;
const TopDragger = styled(VerticalDragger)`
    top: 0;
`;
const BottomDragger = styled(VerticalDragger)`
    bottom: 0;
`;

type PositionType = "left" | "top" | "right" | "bottom" | undefined;

interface IResizableDrawerProps {
    width: number;
    defaultOpen: boolean;
    height: number;
    level: string | string[] | null | undefined;
    maskClosable: boolean;
    placement: PositionType;
    showMask: boolean;
    style: any;
}

interface IResizableDrawerState {
    isResizing: boolean;
    side: PositionType;
    height?: number;
    width?: number;
}

class ResizableDrawer extends Component<IResizableDrawerProps, IResizableDrawerState> {
    constructor(props: IResizableDrawerProps) {
        super(props);
        this.state = {
            isResizing: false,
            side: undefined,
            height: undefined,
            width: undefined,
        };
    }

    componentDidMount() {
        document.addEventListener('mousemove', (e) => this.handleMouseMove(e));
        document.addEventListener('mouseup', () => this.handleMouseUp());
        const { height, width } = this.props;
        this.setState({ height, width });
    }

    handleMousedown = (e: any, side: PositionType) => {
        this.setState({ isResizing: true, side });
    };

    handleMouseMove = (e: any) => {
        if (!this.state.isResizing) {
            return;
        }

        const top = (this.props.style && this.props.style.top)
            ? Number(this.props.style.top.replace('px', ''))
            : 0;

        let newWidth = this.state.width;
        let newHeight = this.state.height;
        switch (this.state.side) {
            case 'left':
                newWidth = document.body.offsetWidth - (e.clientX - document.body.offsetLeft);
                this.setDrawerWidth(newWidth);
                break;
            case 'right':
                newWidth = e.clientX - document.body.offsetLeft;
                this.setDrawerWidth(newWidth);
                break;
            case 'top':
                newHeight = document.body.offsetHeight
                    - (e.clientY - document.body.offsetTop) - top;
                this.setDrawerHeight(newHeight);
                break;
            case 'bottom':
                newHeight = e.clientY - document.body.offsetTop - top;
                this.setDrawerHeight(newHeight);
                break;
            default:
        }
    };

      handleMouseUp = () => {
        this.setState({ isResizing: false });
      };

      setDrawerHeight = (height: number, minHeight = 50, maxHeight = 800) => {
        if (height > minHeight && height < maxHeight) {
            this.setState({ height });
        }
      }

      setDrawerWidth = (width: number, minWidth = 50, maxWidth = 1200) => {
        if (width > minWidth && width < maxWidth) {
            this.setState({ width });
        }
      }

    render() {
        const { children, ...drawerProps } = this.props;
        return (
            <Drawer {...drawerProps} height={this.state.height} width={this.state.width}>
                <LeftDragger onMouseDown={(event) => {
                    this.handleMousedown(event, 'left');
                }}
                />
                <RightDragger onMouseDown={(event) => {
                    this.handleMousedown(event, 'right');
                }}
                />
                <TopDragger onMouseDown={(event) => {
                    this.handleMousedown(event, 'top');
                }}
                />
                <BottomDragger onMouseDown={(event) => {
                    this.handleMousedown(event, 'bottom');
                }}
                />
                {children}
            </Drawer>
        );
    }
}

export default ResizableDrawer;
