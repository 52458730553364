import React from 'react';
import ReactModal from 'react-modal';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';

const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
    },
};

const ButtonsContainer = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-around;
`;

ReactModal.setAppElement('#root');

const Modal = ({
    ...props
}: ReactModal.Props & { text: string } & { handleContinue: () => void }) => (
    <ReactModal {...props} style={modalStyle}>
        <p>{props.text}</p>
        <ButtonsContainer>
            <Button onClick={props.onRequestClose} variant="secondary">
                Cancel
            </Button>
            <Button onClick={props.handleContinue} variant="danger">
                Yes
            </Button>
        </ButtonsContainer>
    </ReactModal>
);

export default Modal;
