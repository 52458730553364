// @ts-ignore
import SeamlessImmutableFunc from 'seamless-immutable';

import { Annotation } from '../types/data/Annotation';
import { AnnotationMode } from '../types/data/AnnotationMode';
import { Model } from '../types/data/Model';
import { Slide } from '../types/data/Slide';
import { Prediction } from '../types/data/Prediction';

export class MainState {
           public static SLIDE = () => 'slide';

           public slide?: Slide = undefined;

           public static SLIDES = () => 'slides';

           public slides: { [slideUuid: string]: Slide } = {};

           public static SLIDES_UPDATED_TIME = () => 'slidesUpdatedTime';

           public slidesUpdatedTime: { [slideUuid: string]: string } = {};

           public static ALERTS = () => 'alerts';

           public alerts: {
               [alertUuid: string]: { subject: string; content: string };
           } = {};

           public static ANNOTATIONS = () => 'annotations';

           public annotations: {
               [slideUuid: string]: { [annotation_uuid: string]: Annotation };
           } = {};

           public static PROFILER_PREDICTIONS = () => 'profilerPredictions';

           public profilerPredictions: {
               [slideUuid: string]: {[taskName: string]: Prediction }
            } = {};

           public static ANNOTATION_MODES = () => 'annotationModes';

           public annotationModes: {[uuid: string]: AnnotationMode} = {};

           public static MODELS = () => 'models';

           public models: { [modelUuid: string]: Model } = {};

           public static PAGINATION = () => 'pagination';

           public pagination: {
               [page: string]: {
                   currentPage: number;
                   pageSize: number;
                   slidesCount: number;
               };
           } = { slides: { currentPage: 1, pageSize: 20, slidesCount: 0 } };

           public static PROGRESS = () => 'progress';

           public progress: {
               [progressUuid: string]: { subject: string; percentage: number };
           } = {};

           public static SEGMENTATION_PREDICTIONS = () => 'segmentation_predictions';

           public segmentation_predictions: {
               [slideUuid: string]: { [segmentationPredictionUuid: string]: Prediction };
           } = {};

           public static PREDICTING_SLIDES = () => 'predictingSlides';

           public predictingSlides: { [slideUuid: string]: boolean} = {};
       }

export const baseState = SeamlessImmutableFunc<MainState>(new MainState());
