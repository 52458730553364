import { Action } from 'redux';
import axios, { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import uuidv4 from 'uuid/v4';

import {
    addAlert,
    addAllAnnotations,
    addAnnotation,
    addAnnotations,
    addAllProfilerPredictions,
    addProgress,
    addSegmentations,
    setAnnotationModes,
    setIsEditing,
    setModels,
    setPagination,
    setPredictionClassCounts,
    setSlide,
    setSlides,
    setSlideStatus,
    setSlideUpdatedTime,
    setTissueRatio,
    updatePredictionProgress,
    registerPrediction,
} from './ActionCreators';
import { MainState } from '../../reducers/MainState';
import { API_BASE, PANAKEIA_LAB_UUID } from '../../constants';
import { SYNC_STATUS } from "../data/Slide";

export const createAnnotation = (
           authToken: string,
           slideUuid: string,
           annotationModeUuid: string,
           user: string,
       ) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
           const url = `${API_BASE}/histoslide/annotations`;
           axios
               .post(
                   url,
                   { annotationModeUuid, slideUuid, user },
                   { headers: { authorization: `Bearer ${authToken}` } },
               )
               .then((res) => {
                   dispatch(addAlert(
                       'New annotation',
                       'A new blank annotation was created',
                   ));
                   const { annotation } = res.data;
                   dispatch(addAnnotation(slideUuid, annotation))
               })
               .catch(() => {
                   dispatch(addAlert('API Error', 'Could not create new annotation'));
               });
       };

export const createEditingMask = (
    authToken: string,
    slideUuid: string,
    maskUuid: string,
    user: string,
) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = `${API_BASE}/histoslide/${maskUuid}/mask/edit`;
    axios
        .post(
            url,
            { user },
            { headers: { authorization: `Bearer ${authToken}` } },
        )
        .then(() => {
            console.log('Editing mask created');
            dispatch(setIsEditing(slideUuid, true));
        })
        .catch((err) => {
            if (err.response.status === 409) {
                dispatch(
                    addAlert(
                        'Access conflict',
                        'Someone else is already editing the case.',
                    ),
                );
            } else {
                dispatch(addAlert('API Error', 'Error creating editing mask'));
                console.log('Error creating editing mask', err);
            }
        });
};

export const fetchAnnotationModes = (authToken: string, user: string) => (
           dispatch: ThunkDispatch<MainState, any, Action>,
       ) => {
           const url = `${API_BASE}/histoslide/annotation_modes`;
           axios
               .get(url, { headers: { authorization: `Bearer ${authToken}` }, params: { user } })
               .then((res: AxiosResponse) => {
                   const annotationModes = res.data;
                   dispatch(setAnnotationModes(annotationModes));
               })
               .catch(() => {
                   dispatch(
                       addAlert('API Error', 'Error fetching annotation modes'),
                   );
               });
       };

export const fetchModels = (authToken: string) => (
    dispatch: ThunkDispatch<MainState, any, Action>,
) => {
    const url = `${API_BASE}/histoslide/models`;
    axios
        .get(url, { headers: { authorization: `Bearer ${authToken}` } })
        .then((res: AxiosResponse) => {
            const models = res.data.map(
                ({
                     uuid,
                     name,
                     level,
                     overlap,
                     in_out_ratio,
                     num_classes,
                     labels_dict,
                     type,
                     created_at,
                 }: any) => ({
                    uuid,
                    name,
                    level,
                    overlap,
                    inOutRatio: in_out_ratio,
                    numClasses: num_classes,
                    labelsDict: JSON.stringify(labels_dict),
                    type,
                    createdAt: created_at,
                }),
            );

            dispatch(setModels(models));
        })
        .catch((err) => {
            dispatch(addAlert('API Error', 'Error listing prediction models'));
            console.log('Error listing prediction models', err);
        });
};

export const fetchSlides = (
           authToken: string,
           user: string,
           page: number,
           sorting: { prop: String; order: string } = { prop: '', order: '' },
           includeAnnotations = true,
           includeProfilerPredictions = false,
           search = '',
       ) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
           const url = `${API_BASE}/histoslide`;
           axios
               .get(url, {
                   headers: { authorization: `Bearer ${authToken}` },
                   params: {
                       user,
                       page,
                       sortingProp: sorting.prop,
                       sortingOrder: sorting.order,
                       includeAnnotations,
                       includeProfilerPredictions,
                       search,
                    },
               })
               .then((res: AxiosResponse) => {
                   dispatch(setSlides(res.data.slides));
                   if (includeAnnotations) {
                    dispatch(addAllAnnotations(res.data.annotations));
                   }
                   if (includeProfilerPredictions) {
                    dispatch(addAllProfilerPredictions(res.data.profilerPredictions));
                   }
                   dispatch(
                       setPagination('slides', {
                           currentPage: page,
                           slidesCount: res.data.count,
                       }),
                   );
                   return res.data;
               })
               .catch((err) => {
                   dispatch(addAlert('API Error', 'Error listing slides'));
                   console.log('Error listing slides', err);
               });
       };


export const syncSlide = (
    authToken: string,
    slideUuid: string,
    modelUuids: string[],
    user: string,
) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = `${API_BASE}/histoslide/${slideUuid}/sync`;
    dispatch(setSlideStatus(slideUuid, SYNC_STATUS.SYNCING));
    axios
        .post(
            url,
            { user, model_uuids: modelUuids },
            { headers: { authorization: `Bearer ${authToken}` } },
        )
        .then((res) => {
            const { slide } = res.data;
            slide.updated = true;
            dispatch(setSlide(slide));
            dispatch(addAnnotations(slideUuid, res.data.annotations));
            dispatch(addSegmentations(slideUuid, res.data.segmentationPredictions));
            dispatch(setSlideStatus(slideUuid, SYNC_STATUS.SYNCED));
            dispatch(addAllProfilerPredictions({ [slide.uuid]: res.data.profilerPredictions }));
        })
        .catch((err) => {
            console.log('Error syncing slide', err);
            dispatch(setSlideStatus(slideUuid, SYNC_STATUS.ERROR));
            dispatch(addAlert(
                'Error syncing slide',
                'Slide could not be imported into PANnotator',
            ))
        });
};

export const predict = (
    authToken: string,
    slideUuid: string,
    modelId: string,
    user: string,
) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = `${API_BASE}/histoslide/predict/${slideUuid}/${modelId}`;
    axios
        .put(
            url,
            { user },
            { headers: { authorization: `Bearer ${authToken}` } },
        )
        .then(() => {
            dispatch(addAlert('Slide update', 'Prediction is being updated', true));
            dispatch(registerPrediction(slideUuid, modelId))
        })
        .catch((err) => {
            if (err.response && err.response.status === 409) {
                dispatch(
                    addAlert(
                        'Access conflict',
                        'Someone else is already editing or predicting the case.',
                    ),
                );
            } else {
                dispatch(addAlert('API Error', 'Error predicting'));
                console.log('Error predicting', err);
            }
        });
};


/* Fetches the distribution of classes in the segmentation prediction */
export const fetchPredictionClassCounts = (
    authToken: string, segmentationPredictionUuid: string,
) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = `${API_BASE}/histoslide/prediction/${segmentationPredictionUuid}/class_counts`
    axios
        .get(url, { headers: { authorization: `Bearer ${authToken}` } })
        .then((res: AxiosResponse) => {
            dispatch(setPredictionClassCounts(res.data.slideUuid, segmentationPredictionUuid,
                res.data.labelsCounts));
            dispatch(setTissueRatio(res.data.slideUuid, segmentationPredictionUuid,
                res.data.tissueRatio));
        })
        .catch((err) => {
            const errorMessage = 'Error fetching prediction class counts'
            dispatch(addAlert('API Error', errorMessage));
            console.log(errorMessage, err);
        });
};

export const resetMask = (
    authToken: string,
    slideUuid: string,
    maskUuid: string,
    user: string,
) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = `${API_BASE}/histoslide/${maskUuid}/mask/reset`;
    axios
        .post(
            url,
            { user },
            { headers: { authorization: `Bearer ${authToken}` } },
        )
        .then(() => {
            console.log('Mask reset done');
            dispatch(setIsEditing(slideUuid, false));
        })
        .catch((err) => {
            dispatch(addAlert('API Error', 'Error resetting mask'));
            console.log('Error resetting mask', err);
        });
};

export const saveMask = (
           authToken: string,
           slideUuid: string,
           maskUuid: string,
           modelUuid: string,
           user: string,
       ) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
           const url = `${API_BASE}/histoslide/${maskUuid}/mask/save`;
           axios
               .post(
                   url,
                   { user, model_uuid: modelUuid },
                   { headers: { authorization: `Bearer ${authToken}` } },
               )
               .then((res) => {
                   if (res && res.data) {
                       dispatch(addAnnotation(slideUuid, res.data.annotation));
                   }
                   dispatch(setIsEditing(slideUuid, false));
               })
               .catch((err) => {
                   dispatch(addAlert('API Error', 'Error saving mask'));
                   console.log('Error saving mask', err);
               });
       };

export const updateAnnotations = (
    authToken: string,
    slideUuid: string,
    maskUuid: string,
    annotations: any,
    tool: string,
    thickness: number,
    user: string,
) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = `${API_BASE}/histoslide/${maskUuid}/polygons`;
    axios
        .post(
            url,
            {
                user, annotations, tool, thickness,
            },
            { headers: { authorization: `Bearer ${authToken}` } },
        )
        .then((res: AxiosResponse) => {
            dispatch(addAlert('Slide update', 'Annotations updated', true));
            dispatch(setSlideUpdatedTime(slideUuid, res.data));
        })
        .catch((err) => {
            dispatch(addAlert('API Error', 'Error updating annotations'));
            console.log('Error updating annotations', err);
        });
};

export const updateModel = (
    authToken: string,
    slideUuid: string,
    maskUuid: string,
    modelUuid: string,
    user: string,
) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = `${API_BASE}/histoslide/${slideUuid}/updatemodel`;
    axios
        .post(
            url,
            { user, mask_uuid: maskUuid, model_uuid: modelUuid },
            { headers: { authorization: `Bearer ${authToken}` } },
        )
        .then(() => {
            dispatch(
                addAlert(
                    'Prediction model update',
                    'Prediction model is being updated',
                    true,
                ),
            );
        })
        .catch((err) => {
            dispatch(addAlert('API Error', 'Error updating model'));
            console.log('Error updating prediction model', err);
        });
};

export const uploadModel = (
    authToken: string,
    name: string,
    file: any,
    openslideLevel: number,
    overlap: number,
    inOutRatio: number,
    numClasses: number,
    labelsDict: any,
    user: string,
) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    if (file) {
        const url = `${API_BASE}/histoslide/models`;
        const formData = new FormData();
        formData.append('name', name);
        formData.append('file', file);
        // @ts-ignore
        formData.append('level', openslideLevel);
        // @ts-ignore
        formData.append('overlap', overlap);
        // @ts-ignore
        formData.append('in_out_ratio', inOutRatio);
        // @ts-ignore
        formData.append('num_classes', numClasses);
        formData.append('labels_dict', JSON.stringify(labelsDict));
        formData.append('user', user);

        const progressUuid = uuidv4();
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${authToken}`,
            },
            onUploadProgress: (progressEvent: any) => dispatch(
                    addProgress(
                        `${name} upload`,
                        progressUuid,
                        (100 * progressEvent.loaded) / file.size,
                    ),
                ),
        };
        axios
            .post(url, formData, config)
            .then((res: AxiosResponse) => {
                console.log('Success', res);
            })
            .catch((err) => {
                dispatch(
                    addAlert('API Error', 'Error uploading prediction model'),
                );
                console.log('Error uploading prediction model', err);
            });
    } else {
        dispatch(addAlert('Upload Error', 'File not selected'));
    }
};

export const uploadSlide = (
    authToken: string,
    name: string,
    inserted_by: string,
    scanned_date: string,
    file: any,
    model_id?: string,
) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    if (file) {
        const url = `${API_BASE}/histoslide/new`;
        const formData = new FormData();
        formData.append('name', name);
        formData.append('inserted_by', inserted_by);
        formData.append('scanned_date', scanned_date);
        formData.append('lab_uuid', PANAKEIA_LAB_UUID); // TODO discern from user and pass as argument
        formData.append('file', file);
        if (model_id !== undefined) {
            formData.append('model_uuid', model_id);
        }
        const progressUuid = uuidv4();
        const config = {
            headers: {
                'Content-Type': 'multipart/form-data',
                authorization: `Bearer ${authToken}`,
            },
            onUploadProgress: (progressEvent: any) => dispatch(
                    addProgress(
                        `${name} upload`,
                        progressUuid,
                        (100 * progressEvent.loaded) / file.size,
                    ),
                ),
        };
        axios
            .post(url, formData, config)
            .then((res: AxiosResponse) => {
                console.log('Success', res);
            })
            .catch((err) => {
                dispatch(addAlert('API Error', 'Error uploading slide'));
                console.log('Error uploading slide', err);
            });
    } else {
        dispatch(addAlert('Upload Error', 'File not selected'));
    }
};


export const getPredictionProgress = (
    authToken: string,
    slide_uuids: string[],
) => (dispatch: ThunkDispatch<MainState, any, Action>) => {
    const url = `${API_BASE}/histoslide/predictions`;
    axios
        .post(url, { slide_uuids }, { headers: { authorization: `Bearer ${authToken}` } })
        .then((res: AxiosResponse) => {
            dispatch(updatePredictionProgress(res.data))
        })
        .catch((err) => {
            dispatch(addAlert('API Error', 'Error updating prediction progress', true));
            console.log('Error updating prediction progress', err)
        })
};
