import React from 'react';
import { sum } from 'lodash';
import styled from 'styled-components';
import { Prediction } from '../types/data/Prediction';

const ColoredDiv = styled.div`
    color: ${(props: {'color': string}) => props.color}
`;

export const formatLabel = (label?: number) => {
    if (label === 1) {
        return <ColoredDiv color="green">Positive</ColoredDiv>;
    } if (label === 0) {
        return <ColoredDiv color="red">Negative</ColoredDiv>;
    }
        return 'Label missing';
}

export const formatResult = (result?: string) => {
    if (result === 'Positive') {
        return <ColoredDiv color="green">Positive</ColoredDiv>;
    } if (result === 'Negative') {
        return <ColoredDiv color="red">Negative</ColoredDiv>;
    }
        return 'Label missing';
}

export const formatReliablePrediction = (label?: number) => {
    if (label === 1) {
        return <ColoredDiv color="green">Positive</ColoredDiv>;
    }
    if (label === 0) {
        return <ColoredDiv color="red">Negative</ColoredDiv>;
    }
    if (label === -1) {
        return <ColoredDiv color="orange">Indeterminate</ColoredDiv>;
    }
        return 'Label missing'
}

export const formatProfilerStatus = (
    profilerPredictions: {[taskName: string]: Prediction},
    taskName: string,
) => {
    const prediction = profilerPredictions && profilerPredictions[taskName];
    return prediction && formatLabel(prediction.label)
}

export const formatLabelPercentage = (prediction: Prediction, label: string) => {
    if (label && prediction !== undefined && Object.keys(prediction.labelsCounts).length !== 0) {
        // @ts-ignore
        const labelCount = prediction.labelsCounts[prediction.mask.label_dictionary[label]];
        const labelsTotal = sum(Object.values(prediction.labelsCounts));
        let percentage = (labelCount * 100) / labelsTotal;
        if (prediction.tissueRatio) {
            percentage /= prediction.tissueRatio;
        }
        return `${percentage.toFixed(2)}%`;
    }
        return 'Percentage unknown';
}
