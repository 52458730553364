// This file has its origin from:
// https://github.com/altert/OpenseadragonFabricjsOverlay/blob/b28ff07501d63b8f105af0b8b7af82b73717eef3/openseadragon-fabricjs-overlay.js
// but it was modified to handle paperjs instead of fabricjs.

// OpenSeadragon canvas Overlay plugin 0.0.1 based on svg overlay plugin
import OpenSeadragon from 'openseadragon';
import paper from 'paper';

OpenSeadragon.Viewer.prototype.paperjsOverlay = function() {
    if (this._paperjsOverlayInfo) {
        return this._paperjsOverlayInfo;
    }

    this._paperjsOverlayInfo = new Overlay(this);
    return this._paperjsOverlayInfo;
};

// ----------
var Overlay = function(viewer) {
    const self = this;

    this._viewer = viewer;

    this._containerWidth = 0;
    this._containerHeight = 0;

    this._canvasdiv = document.createElement('div');
    this._canvasdiv.style.position = 'absolute';
    this._canvasdiv.style.left = 0;
    this._canvasdiv.style.top = 0;
    this._canvasdiv.style.width = '100%';
    this._canvasdiv.style.height = '100%';
    this._viewer.canvas.appendChild(this._canvasdiv);

    this._canvas = document.createElement('canvas');
    this._canvas.setAttribute('id', 'osd-overlaycanvas');
    this._canvasdiv.appendChild(this._canvas);
    this.resize();

    paper.setup(this._canvas);

    this._viewer.addHandler('update-viewport', function(e) {
        self.resize();
        self.resizecanvas();
    });

    this._viewer.addHandler('open', function() {
        self.resize();
        self.resizecanvas();
    });

    this.resize();
};

// ----------
Overlay.prototype = {
    // ----------
    paperCanvas() {
        return this._canvas;
    },
    clear() {
        // TODO: check what needs to be added here
    },
    // ----------
    resize() {
        if (this._containerWidth !== this._viewer.container.clientWidth) {
            this._containerWidth = this._viewer.container.clientWidth;
            this._canvasdiv.setAttribute('width', this._containerWidth);
            this._canvas.setAttribute('width', this._containerWidth);
        }
        if (this._containerHeight !== this._viewer.container.clientHeight) {
            this._containerHeight = this._viewer.container.clientHeight;
            this._canvasdiv.setAttribute('height', this._containerHeight);
            this._canvas.setAttribute('height', this._containerHeight);
        }
    },
    resizecanvas() {
        this._canvasdiv.setAttribute('width', this._containerWidth);
        this._canvas.setAttribute('width', this._containerWidth);
        this._canvasdiv.setAttribute('height', this._containerHeight);
        this._canvas.setAttribute('height', this._containerHeight);
        paper.view.viewSize = new paper.Size(
            this._containerWidth,
            this._containerHeight,
        );
        const viewportZoom = this._viewer.viewport.getZoom(true);
        const image1 = this._viewer.world.getItemAt(0);
        paper.view.zoom = image1.viewportToImageZoom(viewportZoom);
        const center = image1.viewportToImageCoordinates(
            this._viewer.viewport.getCenter(true),
        );
        paper.view.center = new paper.Point(center.x, center.y);
    },
};

export default OpenSeadragon;
