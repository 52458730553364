import React from 'react';

import styled from 'styled-components';

const OpenSeadragon = styled.div``;

const OpenseadragonWrapper = () => (
    <OpenSeadragon
        id="osd-viewer"
        className="openseadragon"
        style={{
            width: '100%',
            height: '100%',
            backgroundColor: '#e9f1f8',
        }}
    />
);

export default OpenseadragonWrapper;
