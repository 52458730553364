import React from 'react';
import ReactModal from 'react-modal';
import { Form } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button, Select } from 'element-react';
import styled from 'styled-components';

import { MainState } from '../../reducers/MainState';
import { Auth0Context } from '../../infrastructure/Auth0/Auth0Provider';
import { createAnnotation, fetchAnnotationModes } from '../../types/actions/AsyncActionCreators';
import { AnnotationMode } from '../../types/data/AnnotationMode';

const StyledForm = styled(Form)`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 80%;
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
`;
const Title = styled.h3`
    font-size: 18px;
    font-weight: 400;
`

const modalStyle = {
    content: {
        top: '30%',
        left: '30%',
        right: '30%',
        bottom: '30%',
    },
};

ReactModal.setAppElement('#root');

type CreateAnnotationModalProps = {
    annotationModes: Array<AnnotationMode>,
    createAnnotation: (
           authToken: string,
           annotationModeUuid: string,
           user: string,
       ) => void
    fetchAnnotationModes: (authToken: string, user: string) => void,
    isOpen: boolean,
    onRequestClose: () => void,
    contentLabel: string,
    slideUuid: string,
};
type CreateAnnotationModalState = {
    selectedAnnotationMode: string | null
};

class CreateAnnotationModal extends React.Component<
    CreateAnnotationModalProps,
    CreateAnnotationModalState
> {
    constructor(props: CreateAnnotationModalProps) {
        super(props);
        this.state = {
            selectedAnnotationMode: null,
        };
    }

    componentDidUpdate(prevProps: CreateAnnotationModalProps) {
        if (!prevProps.isOpen && this.props.isOpen && this.context.isAuthenticated) {
            this.context.getTokenSilently().then((authToken: string) => {
                this.props.fetchAnnotationModes(
                    authToken,
                    this.context.user.sub,
                );
            });
        }
    }

    createAnnotation = () => {
        this.context.getTokenSilently().then((authToken: string) => {
            const { selectedAnnotationMode } = this.state;
            if (selectedAnnotationMode) {
                this.props.createAnnotation(
                    authToken,
                    selectedAnnotationMode,
                    this.context.user.sub,
                );
            }
        });
        this.props.onRequestClose();
    };

    onChangeAnnotationMode = (annotationModeUuid: string) => {
        this.setState({ selectedAnnotationMode: annotationModeUuid });
    };

    render() {
        const {
            annotationModes,
            isOpen,
            onRequestClose,
            contentLabel,
        } = this.props;
        const { selectedAnnotationMode } = this.state;
        const modalProps = { isOpen, onRequestClose, contentLabel };
        return (
            <ReactModal {...modalProps} style={modalStyle}>
                <Wrapper>
                    <Title>Create annotation</Title>
                    <StyledForm>
                        <div>
                            <p>Annotation mode:</p>
                            <Select
                                onChange={this.onChangeAnnotationMode}
                                value={selectedAnnotationMode}
                            >
                                {annotationModes.map(({ uuid, name }) => (
                                    <Select.Option
                                        key={uuid}
                                        label={name}
                                        value={uuid}
                                    />
                                ))}
                            </Select>
                        </div>
                        <Button onClick={this.createAnnotation}>Create</Button>
                    </StyledForm>
                </Wrapper>
            </ReactModal>
        );
    }
}

const mapStateToProps = (state: MainState) => ({
    annotationModes: Object.values(state.annotationModes),
});

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
    createAnnotation: (
        authToken: string,
        annotationModeUuid: string,
        user: string,
    ) =>
        dispatch(
            createAnnotation(
                authToken,
                ownProps.match.params.slideUuid,
                annotationModeUuid,
                user,
            ),
        ),
    fetchAnnotationModes: (authToken: string, user: string) =>
        dispatch(fetchAnnotationModes(authToken, user)),
});

CreateAnnotationModal.contextType = Auth0Context;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CreateAnnotationModal));
