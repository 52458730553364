import React, { Component } from 'react';
import { connect } from 'react-redux';
import Collapsible from 'react-collapsible';
import styled from 'styled-components';

import { PREDICTION_UPDATE_INTERVAL, CELL_LEVEL_SEGMENTATION_TASK_NAME } from '../../constants';
import { fetchPredictionClassCounts, getPredictionProgress } from '../../types/actions/AsyncActionCreators';
import { Auth0Context } from '../../infrastructure/Auth0/Auth0Provider';
import { MainState } from '../../reducers/MainState';
import { Slide } from '../../types/data/Slide';
import '../../styles/Collapsible.scss';
import { Prediction } from '../../types/data/Prediction';
import {
 formatLabel, formatLabelPercentage, formatReliablePrediction, formatResult,
} from '../../utils/format';
import ResizableDrawer from '../ResizableDrawer';
import { getCmapFromDict } from '../../utils/colormaps';
import { Mask } from "../../types/data/Mask";

const DetectionControls = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4px;
`;
const Grid = styled.div`
    display: grid;
    grid-template-columns: auto auto;
`
const GridElementName = styled.div`
    border: 1px solid grey;
    border-radius: 3px;
    margin: 4px;
    padding-left: 4px;
    font-size: 11px;
`
const GridElementValue = styled.div`
    border: 1px solid grey;
    border-radius: 3px;
    margin: 4px;
    padding-left: 4px;
    font-weight: 300;
    font-size: 11px;
`;
const ActiveClickableGridElementName = styled(GridElementName)`
    cursor: pointer;
    border: 1px solid green;
    color: green;
`;
const InactiveClickableGridElementName = styled(GridElementName)`
    cursor: pointer;
    border: 1px solid grey;
    color: grey;
`;
const LabelsLegend = styled.p`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    height: 100%;
    font-size: 11px;
    padding-right: 2px;
    text-transform: capitalize;
`;
const LegendContainer = styled.div`
    display: flex;
    flex-direction: column;
    padding: 4px;
`;
const LegendItem = styled.div`
    border-right: 12px solid ${(props) => props.color};
    border-top: 3px solid ${(props) => props.color};
    border-bottom: 3px solid ${(props) => props.color};
    cursor: pointer;
`;

interface IInheritedProps {
    changeMask: (mask: Mask) => void;
    match?: any;
    maskUuid?: string;
    profilerPredictions: { [taskName: string]: Prediction };
    slide?: Slide;
    segmentationPrediction?: Prediction;
}

interface IDispatchProps {
    fetchPredictionClassCounts: (authToken: string, segmentationPredictionUuid: string) => void;
    getPredictionProgress: (authToken: string, slideUuids: string[]) => void;
}

type IProfilerResultsProps = IInheritedProps & IDispatchProps;

class ProfilerResults extends Component<IProfilerResultsProps> {
    private updatePredictionProgressInterval: any;

    componentDidMount() {
        if (this.context.isAuthenticated) {
            this.context.getTokenSilently().then((authToken: string) => {
                this.updatePredictionProgressInterval = setInterval(() => {
                    // @ts-ignore
                    this.props.getPredictionProgress(authToken, [this.props.slide.uuid]);
                }, PREDICTION_UPDATE_INTERVAL);
            });
        }
    }

    componentDidUpdate(prevProps: IProfilerResultsProps) {
        if ((!prevProps.segmentationPrediction && this.props.segmentationPrediction)
            || (prevProps.segmentationPrediction && this.props.segmentationPrediction
                && (prevProps.segmentationPrediction.uuid
                !== this.props.segmentationPrediction.uuid))) {
                    if (this.context.isAuthenticated) {
                        this.context.getTokenSilently().then((authToken: string) => {
                            this.props.fetchPredictionClassCounts(authToken,
                            // @ts-ignore
                            this.props.segmentationPrediction.uuid);
                        });
                    }
                }
    }

    renderProfilerPredictionStatus(prediction: Prediction) {
        const gridElements = [];
        const taskName = prediction.task_name.toUpperCase()
        if (this.props.slide && prediction.task_name in this.props.slide.profiler_labels) {
            gridElements.push(
                <>
                    <GridElementName>{`${taskName} Label`}</GridElementName>
                    <GridElementValue>
                        {formatResult(this.props.slide.profiler_labels[prediction.task_name])}
                    </GridElementValue>
                </>,
            );
        }
        gridElements.push(
            <>
                <GridElementName>{`${taskName} Prediction`}</GridElementName>
                <GridElementValue>{formatLabel(prediction.label)}</GridElementValue>
            </>,
        );
        if (prediction.details && 'score' in prediction.details) {
            gridElements.push(
                <>
                    <GridElementName>{`${taskName} Score`}</GridElementName>
                    <GridElementValue>{prediction.details.score}</GridElementValue>
                </>,
            )
        }
        if (prediction.details && 'sensitivity_thr' in prediction.details) {
            gridElements.push(
                <>
                    <GridElementName>{`${taskName}- Threshold`}</GridElementName>
                    <GridElementValue>
                        {prediction.details.sensitivity_thr}
                    </GridElementValue>
                </>,
            )
        }
        if (prediction.details && 'specificity_thr' in prediction.details) {
            gridElements.push(
                <>
                    <GridElementName>{`${taskName}+ Threshold`}</GridElementName>
                    <GridElementValue>
                        {prediction.details.specificity_thr}
                    </GridElementValue>
                </>,
            )
        }
        if (prediction.details && 'reliable_prediction' in prediction.details) {
            gridElements.push(
                <>
                    <GridElementName>{`${taskName} Reliable Prediction`}</GridElementName>
                    <GridElementValue>
                        {formatReliablePrediction(prediction.details.reliable_prediction)}
                    </GridElementValue>
                </>,
            )
        }
        return gridElements
    }

    renderMissingProfilerLabels() {
        const gridElements = [];
        if (this.props.slide) {
            for (const taskName in this.props.slide.profiler_labels) {
                if (!(taskName in this.props.profilerPredictions)) {
                    gridElements.push(
                        <>
                            <GridElementName>{`${taskName.toUpperCase()} Label`}</GridElementName>
                            <GridElementValue>
                                {formatResult(this.props.slide.profiler_labels[taskName])}
                            </GridElementValue>
                        </>,
                    );
                }
            }
        }
        return gridElements
    }


    renderMaskDisplayButton(prediction: Prediction, mask: Mask, maskDisplayName: string) {
        return (
            <DetectionControls onClick={() => this.props.changeMask(mask)}>
                {this.props.maskUuid === mask.uuid ? (
                    <>
                        <ActiveClickableGridElementName>
                            {`Toggle ${maskDisplayName}`}
                        </ActiveClickableGridElementName>
                        <LegendContainer>
                            {Object.keys(mask.label_dictionary).map(
                                (label: string) => (
                                    label.toLowerCase() !== 'background' && (
                                        <LegendItem
                                            color={
                                                // @ts-ignore
                                                getCmapFromDict(
                                                    // @ts-ignore
                                                    mask.label_dictionary,
                                                )[mask.label_dictionary[label] * 10]
                                            }
                                            key={label}
                                        >
                                            {label === 'invasive' && prediction.labelsCounts ? (
                                                <LabelsLegend>
                                                    {
                                                        `${label} - ${formatLabelPercentage(prediction, label)}`
                                                    }
                                                </LabelsLegend>
                                            ) : (
                                                <LabelsLegend>
                                                    {label}
                                                </LabelsLegend>
                                            )}
                                        </LegendItem>
                                    )
                                ),
                            )}
                        </LegendContainer>
                    </>
                ) : (
                    <InactiveClickableGridElementName>
                        {`Toggle ${maskDisplayName}`}
                    </InactiveClickableGridElementName>
                    )
                }
            </DetectionControls>
        )
    }

    render() {
        const {
            profilerPredictions, segmentationPrediction, slide,
        } = this.props;

        const maskButtons = [];
        if (segmentationPrediction && segmentationPrediction.mask) {
            maskButtons.push(this.renderMaskDisplayButton(segmentationPrediction,
                segmentationPrediction.mask, 'Segmentation Mask'));
        }
        for (const taskName in profilerPredictions) {
            if (Object.prototype.hasOwnProperty.call(profilerPredictions, taskName)) {
                const profilerPrediction: Prediction = profilerPredictions[taskName]
                if (profilerPrediction.ei_masks) {
                    for (const mask_uuid in profilerPrediction.ei_masks) {
                        if (Object.prototype.hasOwnProperty.call(profilerPrediction.ei_masks,
                            mask_uuid)) {
                            const mask: Mask = profilerPrediction.ei_masks[mask_uuid];
                            const maskDisplayName = `${taskName} ${mask.metadata.type}`
                            maskButtons.push(
                                this.renderMaskDisplayButton(profilerPrediction, mask,
                                    maskDisplayName),
                            )
                        }
                    }
                }
            }
        }

        return slide ? (
            <ResizableDrawer
                defaultOpen
                height={365}
                level={null}
                maskClosable={false}
                placement="right"
                showMask={false}
                style={{ top: '80px' }}
                width={350}
            >
                <Collapsible open trigger="Description">
                    <Grid>
                        <GridElementName>Slide Name</GridElementName>
                        <GridElementValue>{slide.foreign_id}</GridElementValue>
                        <GridElementName>Slide UUID</GridElementName>
                        <GridElementValue>{slide.uuid}</GridElementValue>
                        <GridElementName>Patient</GridElementName>
                        <GridElementValue>{slide.patient_foreign_id}</GridElementValue>
                        <GridElementName>Inserted by</GridElementName>
                        <GridElementValue>{slide.inserted_by}</GridElementValue>
                        <GridElementName>Inserted date</GridElementName>
                        <GridElementValue>{slide.inserted_date}</GridElementValue>
                    </Grid>
                </Collapsible>
                <Collapsible open trigger="Receptor Profiles">
                    <Grid>
                        {Object.values(profilerPredictions)
                            .map(this.renderProfilerPredictionStatus.bind(this))}
                        {slide && slide.profiler_labels && this.renderMissingProfilerLabels()}
                    </Grid>
                </Collapsible>
                <Collapsible open trigger="Segmentation and E&I masks">
                    {maskButtons}
                </Collapsible>
            </ResizableDrawer>
        ) : null;
    }
}

const mapStateToProps = (state: MainState) => ({
    // sort in descending order of creation date to only pick most recent cell-level prediction
    segmentationPrediction: (state.slide && state.segmentation_predictions[state.slide.uuid])
    ? Object.values(state.segmentation_predictions[state.slide.uuid])
        .sort((a, b) => (a.creation_date > b.creation_date ? -1 : 1))
        .filter((s) => (s.model_uuid !== null)
        && (s.task_name === CELL_LEVEL_SEGMENTATION_TASK_NAME) && s.mask)[0]
    : undefined,
    profilerPredictions: (state.slide && state.profilerPredictions[state.slide.uuid])
    ? state.profilerPredictions[state.slide.uuid]
    : {},
    slide: state.slide,
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchPredictionClassCounts: (authToken: string, segmentationPredictionUuid: string) =>
        dispatch(fetchPredictionClassCounts(authToken, segmentationPredictionUuid)),
    getPredictionProgress: (authToken: string, slideUuids: string[]) =>
        dispatch(getPredictionProgress(authToken, slideUuids)),
})

ProfilerResults.contextType = Auth0Context;

export default connect(mapStateToProps, mapDispatchToProps)(ProfilerResults)
