import uuidv4 from 'uuid/v4';
import { baseState, MainState } from './MainState';
import { IBaseAction } from '../types/actions/IBaseAction';
import { actionTypes } from '../types/actions/Action';
import { arrayToObject } from '../utils/helpers';
import { PREDICTION_STATUS_UPDATE_TIMEOUT, SEGMENTATION_TASK_TYPES } from "../constants";

export default (state = baseState, action: IBaseAction<any>) => {
    let newState = state;
    switch (action.type) {
        case actionTypes.ADD_ALERT:
            return state.setIn([MainState.ALERTS(), uuidv4()], {
                subject: action.payload.alertSubject,
                content: action.payload.content,
                autohide: action.payload.autohide,
            });
        case actionTypes.ADD_ANNOTATION:
            return state.setIn(
                [MainState.ANNOTATIONS(), action.payload.slideUuid, action.payload.annotation.uuid],
                action.payload.annotation,
            );
        case actionTypes.ADD_ANNOTATIONS:
            return state.set(
                MainState.ANNOTATIONS(),
                state.annotations.merge(
                    { [action.payload.slideUuid]: action.payload.annotations },
                    { deep: true },
                ),
            );
        case actionTypes.ADD_ALL_ANNOTATIONS:
            return state.set(MainState.ANNOTATIONS(),
                state.annotations.merge(action.payload.annotations, { deep: true }));
        case actionTypes.ADD_PROGRESS:
            return state.setIn(
                [MainState.PROGRESS(), action.payload.progressUuid],
                {
                    hidden: state.getIn([
                        MainState.PROGRESS(),
                        action.payload.progressUuid,
                        'hidden',
                    ]),
                    subject: action.payload.progressSubject,
                    percentage: action.payload.percentage,
                },
            );
        case actionTypes.ADD_ALL_PROFILER_PREDICTIONS:
            return state.set(MainState.PROFILER_PREDICTIONS(),
                state.profilerPredictions.merge(
                    action.payload.profilerPredictions,
                    { deep: true },
                ));
        case actionTypes.ADD_SEGMENTATIONS:
            return state.set(
                MainState.SEGMENTATION_PREDICTIONS(),
                state.annotations.merge(
                    { [action.payload.slideUuid]: action.payload.segmentation_predictions },
                    { deep: true },
                ),
            );
        case actionTypes.HIDE_PROGRESS:
            return state.setIn(
                [MainState.PROGRESS(), action.payload.progressUuid],
                {
                    hidden: true,
                },
            );
        case actionTypes.REMOVE_ALERT:
            return state.setIn(
                [MainState.ALERTS()],
                state
                    .getIn([MainState.ALERTS()])
                    .without(action.payload.alertUuid),
            );
        case actionTypes.SET_ANNOTATION_MODES:
            return state.set(MainState.ANNOTATION_MODES(), action.payload.annotationModes);
        case actionTypes.SET_IS_EDITING:
            if (state.slide && state.slide.uuid === action.payload.slideUuid) {
                newState = newState.setIn([MainState.SLIDE(), 'isEditing'], action.payload.isEditing);
            }
            return newState;
        case actionTypes.SET_MODELS:
            return state.set(
                MainState.MODELS(),
                arrayToObject(action.payload.models, 'uuid'),
            );
        case actionTypes.SET_PAGINATION:
            return state.setIn(
                [MainState.PAGINATION(), action.payload.page],
                state.pagination[action.payload.page].merge(
                    action.payload.pagination,
                ),
            );
        case actionTypes.SET_PREDICTION_CLASS_COUNTS:
            return state.setIn(
                [MainState.SEGMENTATION_PREDICTIONS(), action.payload.slideUuid, action.payload.segmentationPredictionUuid, 'labelsCounts'],
                action.payload.labelsCounts,
            );
        case actionTypes.SET_SLIDE:
            return state.set(
                MainState.SLIDE(),
                action.payload.slide,
            );
        case actionTypes.SET_SLIDES:
            return state.set(
                MainState.SLIDES(),
                arrayToObject(action.payload.slides, 'uuid'),
            );
        case actionTypes.SET_SLIDE_STATUS:
            if (state.slide && state.slide.uuid === action.payload.slideUuid) {
                return state.setIn(
                    [MainState.SLIDE(), 'sync_status'],
                    action.payload.status,
                );
            }
            return state;
        case actionTypes.SET_SLIDE_UPDATED_TIME:
            return state.setIn(
                [MainState.SLIDES_UPDATED_TIME(), action.payload.slideUuid],
                action.payload.updatedTime,
            );
        case actionTypes.SET_TISSUE_RATIO:
            return state.setIn(
                [MainState.SEGMENTATION_PREDICTIONS(), action.payload.slideUuid, action.payload.segmentationPredictionUuid, 'tissueRatio'],
                action.payload.tissueRatio,
            )
        case actionTypes.REGISTER_PREDICTION:
            newState = state.setIn([MainState.PREDICTING_SLIDES(), action.payload.slideUuid], true);
            newState = newState.setIn(
                [MainState.SLIDES(), action.payload.slideUuid, 'prediction_status', action.payload.modelUuid],
                {
                    status: 'running',
                    progress: 0,
                    timestamp: Date.now() / 1000.0,
                },
            );
            if (state.slide && state.slide.uuid === action.payload.slideUuid) {
                newState.setIn(
                    [MainState.SLIDE(), 'prediction_status', action.payload.modelUuid], {
                        status: 'running',
                        progress: 0,
                        timestamp: Date.now() / 1000.0,
                    },
                );
            }
            return newState;

        case actionTypes.UPDATE_PREDICTION_PROGRESS: {
            newState = state;
            // eslint-disable-next-line no-restricted-syntax
            for (const slideUuid of Object.keys(action.payload.predictionProgress)) {
                if (Object.keys(action.payload.predictionProgress[slideUuid]).length === 0) {
                    const now = Date.now() / 1000.0;
                    // eslint-disable-next-line no-restricted-syntax
                    for (const modelUuid of Object.keys(newState.getIn([MainState.SLIDES(), slideUuid, 'prediction_status']))) {
                        const lastUpdateTimestamp
                            = newState.getIn([MainState.SLIDES(), slideUuid, 'prediction_status', modelUuid, 'timestamp']);
                        if (now - lastUpdateTimestamp > PREDICTION_STATUS_UPDATE_TIMEOUT) {
                            newState = newState.setIn([MainState.ALERTS(), uuidv4()], {
                                subject: 'No updates for prediction.',
                                content: `Got no updates for prediction ${newState.slides[slideUuid].foreign_id} using
                                 model ${newState.models[modelUuid].name} for a long time - considering prediction
                                 failed.`,
                            });
                            newState = newState.updateIn([MainState.SLIDES(), slideUuid, 'prediction_status'],
                                (predictionStatus) => predictionStatus.without(modelUuid));
                        }
                    }
                }
                // eslint-disable-next-line no-restricted-syntax
                for (const modelUuid of Object.keys(action.payload.predictionProgress[slideUuid])) {
                    const progress = action.payload.predictionProgress[slideUuid][modelUuid];
                    if (progress.status === 'running') {
                        newState = newState.setIn([MainState.SLIDES(), slideUuid, 'prediction_status', modelUuid], progress)
                        if (state.slide && state.slide.uuid === action.payload.slideUuid) {
                            newState.setIn([MainState.SLIDE(), 'prediction_status', modelUuid], progress);
                        }
                    } else if (progress.status === 'failed') {
                        newState = newState.setIn([MainState.ALERTS(), uuidv4()], {
                            subject: 'Prediction failed',
                            content: `Prediction for slide ${newState.slides[slideUuid].foreign_id} using model ${newState.models[modelUuid].name} failed.`,
                        });
                        newState = newState.updateIn([MainState.SLIDES(), slideUuid, 'prediction_status'],
                            (predictionStatus) => predictionStatus.without(modelUuid));
                        if (state.slide && state.slide.uuid === action.payload.slideUuid) {
                            newState.updateIn([MainState.SLIDE(), 'prediction_status'],
                                (predictionStatus) => predictionStatus.without(modelUuid));
                        }
                    } else if (progress.status === 'finished') {
                        newState = newState.setIn([MainState.ALERTS(), uuidv4()], {
                            subject: 'Prediction finished',
                            content: `Prediction for slide ${newState.slides[slideUuid].foreign_id} using model ${newState.models[modelUuid].name} finished.`,
                        });
                        newState = newState.updateIn([MainState.SLIDES(), slideUuid, 'prediction_status'],
                            (predictionStatus) => predictionStatus.without(modelUuid));
                        if (state.slide && state.slide.uuid === action.payload.slideUuid) {
                            newState.updateIn([MainState.SLIDE(), 'prediction_status'],
                                (predictionStatus) => predictionStatus.without(modelUuid));
                        }
                        const predictionsState =
                            SEGMENTATION_TASK_TYPES.includes(newState.models[modelUuid].type)
                                ? MainState.SEGMENTATION_PREDICTIONS()
                                : MainState.PROFILER_PREDICTIONS();
                        newState = newState.setIn(
                            [predictionsState, slideUuid, progress.prediction.uuid],
                            progress.prediction,
                        );
                    }
                }
                if (Object.keys(newState.getIn([MainState.SLIDES(), slideUuid, 'prediction_status'])).length === 0) {
                    newState = newState.update(MainState.PREDICTING_SLIDES(),
                        (predictingSlides: any) => predictingSlides.without(slideUuid))
                }
            }
            return newState;
        }

        default:
            return state;
    }
};
