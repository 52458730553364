import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table } from 'element-react';

import {
    fetchModels,
} from '../../types/actions/AsyncActionCreators';
import { Model } from '../../types/data/Model';
import { MainState } from '../../reducers/MainState';
import { Auth0Context } from '../../infrastructure/Auth0/Auth0Provider';

const PaddedIcon = styled(FontAwesomeIcon)`
    padding-right: 4px;
`;
const Toolbar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 12px;
`;

interface IModelsTablePageProps {
    history?: any;
    fetchModels: (authToken: string) => any;
    models: Array<Model>;
}

interface IModelsTablePageState {
}

class ModelsTablePage extends Component<
    IModelsTablePageProps,
    IModelsTablePageState
> {
    constructor(props: IModelsTablePageProps) {
        super(props);
        this.state = {};
    }

    componentDidMount(): void {
        if (this.context.isAuthenticated) {
            this.context.getTokenSilently().then((authToken: string) => {
                this.props.fetchModels(authToken);
            });
        }
    }

    redirectToUpload() {
        const path = '/pannotator/models/new';
        this.props.history.push(path);
    }

    render() {
        const columns = [
            {
                label: '#',
                type: 'index',
            },
            {
                label: 'UUID',
                prop: 'uuid',
                resizable: true,
                sortable: true,
            },
            {
                label: 'Name',
                prop: 'name',
                resizable: true,
                sortable: true,
            },
            {
                label: 'Level',
                prop: 'level',
                resizable: true,
                sortable: true,
            },
            {
                label: 'Overlap',
                prop: 'overlap',
                resizable: true,
                sortable: true,
            },
            {
                label: 'In/out ratio',
                prop: 'inOutRatio',
                resizable: true,
                sortable: true,
            },
            {
                label: 'Number of classes',
                prop: 'numClasses',
                resizable: true,
                sortable: true,
            },
            {
                label: 'Labels dictionary',
                prop: 'labelsDict',
                resizable: true,
                width: 300,
                sortable: true,
            },
            {
                label: 'Created at',
                prop: 'createdAt',
                resizable: true,
                sortable: true,
            },
        ];

        return (
            <>
                <Toolbar>
                    <Button
                        onClick={() => this.redirectToUpload()}
                        variant="primary"
                    >
                        <PaddedIcon icon="upload" />
                        Upload new prediction model
                    </Button>
                </Toolbar>
                <Table
                    {...{
                        emptyText: 'No model',
                    }}
                    style={{ width: '100%' }}
                    columns={columns}
                    data={this.props.models}
                    border
                />
            </>
        );
    }
}

const mapStateToProps = (state: MainState) => ({
    models: Object.values(state.models),
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchModels: (authToken: string) => dispatch(fetchModels(authToken)),
});

ModelsTablePage.contextType = Auth0Context;

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(ModelsTablePage),
);
