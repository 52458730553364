import { hot } from 'react-hot-loader/root';
import React, { Component } from 'react';
import './App.css';
import 'bootstrap/dist/css/bootstrap.css';
import { Line } from 'rc-progress';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
    faChalkboardTeacher,
    faCommentMedical,
    faEdit,
    faHistory,
    faImage,
    faImages,
    faSave,
    faSignOutAlt,
    faTimesCircle,
    faUpload,
} from '@fortawesome/free-solid-svg-icons';
import { i18n } from 'element-react';
import locale from 'element-react/src/locale/lang/en';

import { Header, Footer } from 'panakeia-react-common/dist';

import { Redirect, Route } from 'react-router';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { Toast } from 'react-bootstrap';
import styled from 'styled-components';

import { MainState } from './reducers/MainState';
import { removeAlert, hideProgress } from './types/actions/ActionCreators';
import { Login } from './components/Login';
import { NavigationBar } from './components/NavigationBar';
import {
    ProfilerPage,
    SlidePage,
    SlidesTablePage,
    UploadSlidePage,
    ModelsTablePage,
    UploadModelPage,
} from './components/Pages';

import { Auth0Context } from './infrastructure/Auth0/Auth0Provider';
import { AUTH_CONFIG, PREDICTION_UPDATE_INTERVAL } from './constants';
import { getPredictionProgress } from "./types/actions/AsyncActionCreators";

i18n.use(locale);

library.add(
    faChalkboardTeacher,
    faCommentMedical,
    faEdit,
    faHistory,
    faImage,
    faImages,
    faSave,
    faSignOutAlt,
    faTimesCircle,
    faUpload,
);

const ToastWrapper = styled.div`
    position: absolute;
    right: 2px;
    width: 200px;
    z-index: 1000;
`;

interface IAppProps {
    alerts: any;
    location: any;
    progress: any;
    predictingSlides: string[];
    removeAlert: (alertUuid: string) => void;
    hideProgress: (progressUuid: string) => void;
    getPredictionProgress: (authToken: string, slideUuids: string[]) => void;
}

interface IAppState {}

// Ensure we get confirmation from user to close window, tab or refresh
window.addEventListener('beforeunload', (e: any) => {
    // Avoid prompt on login
    if (e.target.activeElement.innerText !== 'Login') {
        const confirmationMessage = 'Do you really want to proceed? You may lose some changes.';
        (e || window.event).returnValue = confirmationMessage;
        return confirmationMessage;
    }
});

class App extends Component<IAppProps, IAppState> {
    private updatePredictionProgressInterval: any;

    componentDidMount(): void {
        this.updatePredictionProgressInterval = setInterval(() => {
            if (this.props.predictingSlides.length > 0) { if (this.context.isAuthenticated) {
                    this.context.getTokenSilently().then((authToken: string) => {
                        this.props.getPredictionProgress(authToken, this.props.predictingSlides);
                    });
                } }
        }, PREDICTION_UPDATE_INTERVAL);
    }

    componentWillUnmount() {
        if (this.updatePredictionProgressInterval) {
            clearInterval(this.updatePredictionProgressInterval);
        }
    }

    handleCloseAlert = (alertUuid: string): void => {
        this.props.removeAlert(alertUuid);
    };

    handleCloseProgress = (progressUuid: string): void => {
        this.props.hideProgress(progressUuid);
    };

    async logout() {
        await this.context.logout({
            returnTo: AUTH_CONFIG.logout_uri,
        });
    }

    render() {
        const destinations = {
            slides: {
                target: '/pannotator',
                displayName: 'PANnotator slides',
            },
            profiler: {
                target: '/profiler-viewer/ce16b91c-73ca-48f6-b176-db7d9e3d1ad2',
                displayName: 'PANprofiler',
            },
            models: {
                target: '/pannotator/models',
                displayName: 'PANnotator prediction models',
            },
        };

        const { alerts, progress } = this.props;

        const routes = [
            {
                path: '/(|pannotator)',
                component: SlidesTablePage,
            },
            {
                path: '/pannotator/new',
                component: UploadSlidePage,
            },
            {
                path: '/pannotator/slide/:slideUuid',
                component: SlidePage,
            },
            {
                path: '/pannotator/models',
                component: ModelsTablePage,
            },
            {
                path: '/pannotator/models/new',
                component: UploadModelPage,
            },
            {
                path: '/profiler-viewer/:slideUuid',
                component: ProfilerPage,
            },
        ];

        const page = this.context.isAuthenticated ? (
            <div className="page">
                {routes.map((route) => (
                    <Route
                        exact
                        key={route.path}
                        path={route.path}
                        render={(props) => <route.component {...props} />}
                    />
                ))}
            </div>
        ) : (
            <Login />
        );

        const isFullScreen = this.props.location.pathname.startsWith(
            '/pannotator/slide/',
        ) || this.props.location.pathname.startsWith(
            '/profiler-viewer/',
        );

        return (
            <div className="App" id="root">
                <ToastWrapper>
                    {Object.keys(alerts).slice(0, 8).map((alertUuid) => (
                        <Toast
                            animation
                            autohide={alerts[alertUuid].autohide}
                            delay={3000}
                            key={alertUuid}
                            onClose={() => this.handleCloseAlert(alertUuid)}
                        >
                            <Toast.Header>
                                <strong className={`mr-auto${alerts[alertUuid].autohide ? '' : alerts[alertUuid].subject === 'Prediction finished' ? ' text-success' : ' text-danger'}`}>
                                    {alerts[alertUuid].subject}
                                </strong>
                            </Toast.Header>
                            <Toast.Body>{alerts[alertUuid].content}</Toast.Body>
                        </Toast>
                    ))}
                    {Object.keys(progress).map((progressUuid) => (progress[progressUuid].hidden ? (
                            ''
                        ) : (
                            <Toast
                                animation
                                key={progressUuid}
                                onClose={() => this.handleCloseProgress(progressUuid)
                                }
                            >
                                <Toast.Header>
                                    <strong className="mr-auto">
                                        {`${
                                            progress[progressUuid].subject
                                        }: ${progress[
                                            progressUuid
                                        ].percentage.toFixed(2)}%`}
                                    </strong>
                                </Toast.Header>
                                <Toast.Body>
                                    <Line
                                        percent={
                                            progress[progressUuid].percentage
                                        }
                                        strokeWidth={4}
                                        strokeColor="#D3D3D3"
                                    />
                                </Toast.Body>
                            </Toast>
                        )))}
                </ToastWrapper>
                {isFullScreen ? (
                    ''
                ) : (
                    <Header
                        appName="PANnotator"
                        appDescription="Panakeia deep annotation tool"
                    />
                )}
                <NavigationBar
                    destinations={destinations}
                    selected={this.props.location.pathname}
                    logout={() => this.logout()}
                    loggedIn={this.context.isAuthenticated}
                />
                {page}
                {isFullScreen ? (
                    ''
                ) : (
                    <Footer />
                )}
                {this.context.isAuthenticated && (
                    <Route
                        path="/callback"
                        render={() => <Redirect to="/pannotator" />}
                    />
                )}
            </div>
        );
    }
}

const mapStateToProps = (state: MainState) => ({
    alerts: state.alerts,
    progress: state.progress,
    predictingSlides: Object.keys(state.predictingSlides),
});

const mapDispatchToProps = (dispatch: any) => ({
    removeAlert: (alertUuid: string) => dispatch(removeAlert(alertUuid)),
    hideProgress: (progressUuid: string) => dispatch(hideProgress(progressUuid)),
    getPredictionProgress: (authToken: string, slideUuids: string[]) =>
        dispatch(getPredictionProgress(authToken, slideUuids)),
});

App.contextType = Auth0Context;

export default hot(
    withRouter(connect(mapStateToProps, mapDispatchToProps)(App)),
);
