
import { Annotation } from './Annotation';

export enum SYNC_STATUS {
    ERROR = 'ERROR',
    NONE = 'NONE',
    SYNCING = 'SYNCING',
    SYNCED = 'SYNCED',
    RENDERING = 'RENDERING',
    DISPLAYED = 'RENDERED',
}

export class Slide {
    public foreign_id!: string;

    public scanned_by!: string;

    public scanned_date!: string;

    public inserted_by!: string;

    public inserted_date!: string;

    public file!: string;

    public uuid!: string;

    public model_uuid!: string;

    public thumbnail?: any;

    public isEditing?: boolean;

    public sync_status!: SYNC_STATUS;

    public annotations?: Annotation[];

    public prediction_status?: any;

    public updated?: boolean;

    public patient_foreign_id!: string;

    // Microns per pixel, used for scale
    public mpp?: number;

    public profiler_labels!: { [task_name: string]: string };
}
