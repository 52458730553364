import React from 'react';
import { Link } from 'react-router-dom';
import './NavigationBar.css';
import Button from 'react-bootstrap/Button';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const isSlidePath = (pathname: string) => pathname.startsWith('/pannotator/slide')

const LogoutButton = styled(Button)`
    margin-right: 12px;
    margin-top: 12px;
    margin-bottom: 12px;
`;
const Wrapper = styled.div`
    position: absolute;
    top: 0;
    z-index: 1;
    width: 100%;
`
const NavButton = styled(Button)`
    background-color: #e9f1f8;
    border: 1px solid #d9e1e8;
    margin-right: 4px;
`;

interface INavigatonBarProps {
    destinations: {
        [id: string]: {
            target: string;
            displayName: string;
        };
    };
    selected: string;
    logout: () => void;
    loggedIn: boolean;
}

const NavigationBar = ({
    destinations,
    loggedIn,
    logout,
    selected,
}: INavigatonBarProps) => {
    const links = Object.keys(destinations).map((id) => {
        const dest = destinations[id];
        return (
            <Link key={id} to={dest.target}>
                {isSlidePath(selected) ? (
                    <NavButton
                        size="sm"
                        variant="light"
                    >
                        {dest.displayName}
                    </NavButton>
                ) : (
                    <div
                        className={
                            selected === dest.target
                                ? 'nav-link active'
                                : 'nav-link'
                        }
                    >
                        {dest.displayName}
                    </div>
                )}
            </Link>
        );
    });

    const SimpleBar = () => (
        <div className="d-flex flex-row justify-content-between align-items-center">
            <div />
            <div className="d-flex flex-row justify-content-center align-items-center">
                {links}
            </div>
            {loggedIn ? (
                <LogoutButton
                    type="button"
                    variant="secondary"
                    onClick={logout}
                >
                    <FontAwesomeIcon icon="sign-out-alt" />
                    Logout
                </LogoutButton>
            ) : (
                    <div />
                )}
        </div>
    )

    return isSlidePath(selected) ? (
        <Wrapper>
            <SimpleBar />
        </Wrapper>
    ) : (
        <SimpleBar />
    );
};

export default NavigationBar;
