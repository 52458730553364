import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import { Pagination, Table } from 'element-react';
import { Button, Form } from 'react-bootstrap';

import { Auth0Context } from '../../infrastructure/Auth0/Auth0Provider';
import { MainState } from '../../reducers/MainState';
import { Prediction } from '../../types/data/Prediction';
import { Slide } from '../../types/data/Slide';
import { fetchSlides } from '../../types/actions/AsyncActionCreators';
import { formatProfilerStatus } from '../../utils/format';
import '../../styles/Collapsible.scss';
import ResizableDrawer from '../ResizableDrawer';

const CenterFlexWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;
const PaginationWrapper = styled.div`
    display: flex;
    justify-content: center;
`;
const ThumbnailCaption = styled.div`
    font-size: 12px;
`;
const SearchForm = styled(Form)`
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    padding: 4px;
`;
const SearchButton = styled(Button)`
    height: 30px;
    margin-left: 10px;
`;

interface IDispatchProps {
    fetchSlides: (
        authToken: string,
        user: string,
        page: number,
        sorting: { prop: string, order: string },
        search? : string
    ) => void;
}

interface IPropsFromState {
    profilerPredictions: {[slideUuid: string]: { [annotationUuid: string]: Prediction }};
    slides: Slide[];
    pagination: {
        currentPage: number;
        pageSize: number;
        slidesCount: number;
    };
}

interface IInheritedProps {
    history?: any;
    match?: any;
}

type ICaseTableProps = IInheritedProps & IPropsFromState & IDispatchProps;

interface ICaseTableState {
    nextSearch: string;
    activeSearch: string;
    sorting: {prop: string, order: string};
}

class CaseTable extends Component<ICaseTableProps, ICaseTableState> {
    constructor(props: ICaseTableProps) {
        super(props);
        this.state = {
            nextSearch: '',
            activeSearch: '',
            sorting: {
                prop: 'inserted_date',
                order: 'descending',
            },
        };
    }

    componentDidMount() {
        if (this.context.isAuthenticated) {
            this.context.getTokenSilently().then((authToken: string) => {
                this.props.fetchSlides(
                    authToken,
                    this.context.user.sub,
                    1,
                    this.state.sorting,
                );
            });
        }
    }

    handleRowClick = (row: Slide) => {
        this.props.history.push(`/profiler-viewer/${row.uuid}`);
    };

    handleSearch = () => {
        const { nextSearch } = this.state;
        this.setState({
            activeSearch: nextSearch,
        })
        if (this.context.isAuthenticated) {
            this.context.getTokenSilently().then((authToken: string) => {
                this.props.fetchSlides(
                    authToken,
                    this.context.user.sub,
                    1,
                    this.state.sorting,
                    this.state.nextSearch,
                );
            });
        }
    }

    handleSortChange = (params: {
        column: any;
        prop: string;
        order: string;
    }) => {
        const { prop, order } = params;
        if (prop !== null && order !== null) {
            this.setState({ sorting: { prop, order } });
        } else {
            this.setState({ sorting: { prop: 'inserted_date', order: 'descending' } });
        }
        if (this.context.isAuthenticated) {
            this.context.getTokenSilently().then((authToken: string) => {
                this.props.fetchSlides(
                    authToken,
                    this.context.user.sub,
                    1,
                    this.state.sorting,
                    this.state.activeSearch,
                );
            });
        }
    };

    onPageChange = (page: number) => {
        if (this.context.isAuthenticated) {
            this.context.getTokenSilently().then((authToken: string) => {
                this.props.fetchSlides(
                    authToken,
                    this.context.user.sub,
                    page,
                    this.state.sorting,
                    this.state.activeSearch,
                );
            });
        }
    };

    onSearchChange = (search: string) => {
        this.setState({ nextSearch: search });
    }

    resetSearch = () => {
        this.setState({ nextSearch: '' });
        this.handleSearch();
    }

    onSubmit = (e: any) => {
        e.preventDefault();
        this.handleSearch();
    }

    render() {
        const { currentPage, pageSize, slidesCount } = this.props.pagination;

        const columns = [
            {
                label: 'Slide',
                prop: 'foreign_id',
                resizable: true,
                sortable: true,
                render: (props: any) =>
                    props.thumbnail && (
                        <CenterFlexWrapper>
                            <img
                                alt="thumbnail"
                                src={`data:image/png;base64,${props.thumbnail}`}
                            />
                            <ThumbnailCaption>{props.foreign_id}</ThumbnailCaption>
                        </CenterFlexWrapper>
                    ),
            },
            {
                label: 'Patient',
                prop: 'patient_foreign_id',
                resizable: true,
            },
            {
                label: 'ER',
                prop: 'er',
                resizable: true,
                render: (props: any) => (
                    <CenterFlexWrapper>
                        {formatProfilerStatus(props.slideProfilerPredictions, 'er')}
                    </CenterFlexWrapper>
                ),
            },
            {
                label: 'PR',
                prop: 'pr',
                resizable: true,
                render: (props: any) => (
                    <CenterFlexWrapper>
                        {formatProfilerStatus(props.slideProfilerPredictions, 'pr')}
                    </CenterFlexWrapper>
                ),
            },
            {
                label: 'HER2',
                prop: 'her2',
                resizable: true,
                render: (props: any) => (
                    <CenterFlexWrapper>
                        {formatProfilerStatus(props.slideProfilerPredictions, 'her2')}
                    </CenterFlexWrapper>
                ),
            },
        ];

        return this.props.slides ? (
            <ResizableDrawer
                defaultOpen
                height={720}
                level={null}
                maskClosable={false}
                placement="left"
                showMask={false}
                style={{ top: '60px' }}
                width={500}
            >
                <SearchForm onSubmit={this.onSubmit}>
                    <Form.Group controlId="formSearch">
                        <Form.Control
                            type="search"
                            onChange={(e: any) => this.onSearchChange(e.target.value)}
                            placeholder="Search patient or slide"
                            value={this.state.nextSearch}
                        />
                    </Form.Group>
                    <SearchButton variant="primary" size="sm" onClick={this.handleSearch}>
                        Search
                    </SearchButton>
                    <SearchButton variant="danger" size="sm" onClick={this.resetSearch}>
                        Reset
                    </SearchButton>
                </SearchForm>
                <Table
                        {...{
                            emptyText: 'No data',
                            onRowClick: this.handleRowClick,
                            onSortChange: this.handleSortChange,
                        }}
                        height={610}
                        style={{ width: '100%' }}
                        columns={columns}
                        data={this.props.slides.map((s: Slide) => (
                            {
                                ...s,
                                slideProfilerPredictions: this.props.profilerPredictions[s.uuid],
                            }
                        ))}
                        border
                />
                <PaginationWrapper>
                    <Pagination
                        currentPage={currentPage}
                        layout="prev, pager, next, jumper, ->, total"
                        onCurrentChange={this.onPageChange}
                        pageSize={pageSize}
                        total={slidesCount}
                    />
                </PaginationWrapper>
            </ResizableDrawer>
        ) : null;
    }
}

const mapStateToProps = (state: MainState) => ({
    pagination: state.pagination.slides,
    profilerPredictions: state.profilerPredictions,
    slides: Object.values(state.slides),
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchSlides: (
        authToken: string,
        user: string,
        page: number,
        sorting: { prop: string; order: string },
        search?: string,
    ) => dispatch(fetchSlides(authToken, user, page, sorting, false, true, search)),
});

CaseTable.contextType = Auth0Context;

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(CaseTable),
);
