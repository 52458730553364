import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import './index.css';
import { Route, Router } from 'react-router';
import { createBrowserHistory } from 'history';
import App from './App';
import * as serviceWorker from './serviceWorker';
import configureStore from './store';
import { Auth0Provider } from './infrastructure/Auth0/Auth0Provider';

const history = createBrowserHistory();

const onRedirectCallback = () => {
    // history.push(
    //     appState && appState.targetUrl
    //         ? appState.targetUrl
    //         : window.location.pathname
    // );
};

ReactDOM.render(
    <Provider store={configureStore()}>
        <Auth0Provider onRedirectCallback={onRedirectCallback}>
            <Router history={history}>
                <Route path="/" render={(props) => <App {...props} />} />
            </Router>
        </Auth0Provider>
    </Provider>,
    document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
