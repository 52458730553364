import React from 'react';
import { Button, Form } from 'react-bootstrap';
import styled from 'styled-components';
import Slider from '@material-ui/core/Slider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { InputNumber } from 'element-react';

import { Model } from '../../../types/data/Model';
import { Auth0Context } from '../../../infrastructure/Auth0/Auth0Provider';
import Modal from '../../Modal';

const PaddedIcon = styled(FontAwesomeIcon)`
    padding-right: 4px;
`;
const SliderWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 200px;
    padding: 3px;
    border-radius: 3px;
    background-color: #e9f1f8;
    border: 1px solid #d9e1e8;
`;
const SliderText = styled.div`
    padding-left: 10px;
    padding-right: 10px;
`;
const StyledSlider = styled(Slider)`
    padding-left: 10px;
    width: 150px;
`;
const Wrapper = styled(Form)`
    position: absolute;
    bottom: 0;
    right: 12px;
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: baseline;
    width: 100%;
    pointer-events: none;
    & > * {
        margin-right: 10px;
        pointer-events: auto;
    }
`;

type ToolbarProps = {
    annotationSelected: number;
    brushWidth: number;
    currentModel: any;
    drawingToolSelected: string;
    handleBrushWidthChange: (value: number) => void;
    handleOpacityChange: (e: any, newValue: number | number[]) => void;
    isDrawing: boolean;
    isEditing: boolean;
    isEditingAllowed: boolean;
    isMaskUpdating: boolean;
    isSaveDisabled: boolean;
    labelsDict?: { [label: string]: number };
    maskUuid?: string;
    models: Model[];
    modelSelected?: string;
    opacity: number | number[];
    overlayVisible: boolean;
    predict: (authToken: string, modelId: string, user: string) => void;
    predictionDisabled: boolean;
    resetEdit: () => void;
    saveEdit: (authToken: string) => void;
    selectAnnotation: (e: any) => void;
    selectDrawingTool: (e: any) => void;
    selectModel: (e: any) => void;
    showMask: boolean;
    toggleDraw: (isDrawing: boolean) => void;
    toggleEdit: () => void;
    updateModel: (authToken: string, maskUuid: string, modelUuid: string, user: string) => void;
    updateShowMask: (e: any) => void;
};

interface IToolbarState {
    isPredictModalOpen: boolean;
    isResetModalOpen: boolean;
    isRetrainModalOpen: boolean;
    isSaveModalOpen: boolean;
}

class Toolbar extends React.Component<ToolbarProps, IToolbarState> {
    constructor(props: ToolbarProps) {
        super(props);
        this.state = {
            isPredictModalOpen: false,
            isResetModalOpen: false,
            isRetrainModalOpen: false,
            isSaveModalOpen: false,
        };
    }

    shouldComponentUpdate(nextProps: ToolbarProps, nextState: IToolbarState) {
        const {
            annotationSelected,
            brushWidth,
            currentModel,
            drawingToolSelected,
            isDrawing,
            isEditing,
            isEditingAllowed,
            isMaskUpdating,
            isSaveDisabled,
            labelsDict,
            models,
            modelSelected,
            opacity,
            overlayVisible,
        } = this.props;
        return (
            this.state !== nextState ||
            annotationSelected !== nextProps.annotationSelected ||
            brushWidth !== nextProps.brushWidth ||
            currentModel !== nextProps.currentModel ||
            drawingToolSelected !== nextProps.drawingToolSelected ||
            isDrawing !== nextProps.isDrawing ||
            isEditing !== nextProps.isEditing ||
            isEditingAllowed !== nextProps.isEditingAllowed ||
            isMaskUpdating !== nextProps.isMaskUpdating ||
            isSaveDisabled !== nextProps.isSaveDisabled ||
            labelsDict !== nextProps.labelsDict ||
            JSON.stringify(models) !== JSON.stringify(nextProps.models) ||
            modelSelected !== nextProps.modelSelected ||
            opacity !== nextProps.opacity ||
            overlayVisible !== nextProps.overlayVisible
        );
    }

    // Predict methods
    closePredictModal = () => {
        this.setState({ isPredictModalOpen: false });
    };

    predict = () => {
        if (this.context.isAuthenticated) {
            this.context.getTokenSilently().then((authToken: string) => {
                if (this.props.modelSelected) {
                    this.props.predict(
                    authToken,
                    String(this.props.modelSelected),
                    this.context.user.sub,
                );
                }
            });
        }
        this.closePredictModal();
    };

    showPredictModal = () => {
        this.setState({ isPredictModalOpen: true });
    };

    // Retrain model methods
    closeRetrainModal = () => {
        this.setState({ isRetrainModalOpen: false });
    };

    retrainModel = () => {
        if (this.context.isAuthenticated) {
            this.context.getTokenSilently().then((authToken: string) => {
                const { maskUuid, modelSelected } = this.props;
                this.props.updateModel(
                    authToken, maskUuid || '', modelSelected || '', this.context.user.sub,
                );
            });
        }
        this.closeRetrainModal();
    };

    showRetrainModal = () => {
        this.setState({ isRetrainModalOpen: true });
    };

    // Reset edit methods
    closeResetModal = () => {
        this.setState({ isResetModalOpen: false });
    };

    resetEdit = () => {
        this.props.resetEdit();
        this.closeResetModal();
    };

    showResetModal = () => {
        this.setState({ isResetModalOpen: true });
    };

    // Save edit methods
    closeSaveModal = () => {
        this.setState({ isSaveModalOpen: false });
    };

    saveAnnotation = () => {
        if (this.context.isAuthenticated) {
            this.context.getTokenSilently().then((authToken: string) => {
                this.props.saveEdit(authToken);
            });
        }
        this.closeSaveModal();
    };

    showSaveModal = () => {
        this.setState({ isSaveModalOpen: true });
    };

    render() {
        const {
            annotationSelected,
            brushWidth,
            currentModel,
            drawingToolSelected,
            handleBrushWidthChange,
            handleOpacityChange,
            isDrawing,
            isEditing,
            isMaskUpdating,
            isSaveDisabled,
            labelsDict,
            models,
            modelSelected,
            opacity,
            overlayVisible,
            selectAnnotation,
            selectDrawingTool,
            selectModel,
            showMask,
            toggleDraw,
            toggleEdit,
            updateShowMask,
        } = this.props;

        return (
            <Wrapper>
                <Modal
                    handleContinue={this.predict}
                    isOpen={this.state.isPredictModalOpen}
                    onRequestClose={this.closePredictModal}
                    contentLabel="Predict"
                    text="Do you really want a new prediction on this slide?"
                />
                <Modal
                    handleContinue={this.resetEdit}
                    isOpen={this.state.isResetModalOpen}
                    onRequestClose={this.closeResetModal}
                    contentLabel="Reset edit"
                    text="Doing this will revert to the previous saved version.  Do you really want to reset?"
                />
                <Modal
                    handleContinue={this.retrainModel}
                    isOpen={this.state.isRetrainModalOpen}
                    onRequestClose={this.closeRetrainModal}
                    contentLabel="Retrain model"
                    text="Do you really want to retrain this model?"
                />
                <Modal
                    handleContinue={this.saveAnnotation}
                    isOpen={this.state.isSaveModalOpen}
                    onRequestClose={this.closeSaveModal}
                    contentLabel="Save annotation"
                    text="Do you really want to save this annotation?"
                />
                {overlayVisible && (
                    <>
                        <SliderWrapper>
                            <SliderText>Opacity:</SliderText>
                            <StyledSlider
                                color="primary"
                                min={0}
                                max={1}
                                step={0.05}
                                valueLabelDisplay="off"
                                value={
                                    typeof opacity === 'number' ? opacity : 0
                                }
                                onChange={handleOpacityChange}
                                aria-labelledby="continuous-slider"
                            />
                        </SliderWrapper>
                        {isEditing && (
                            <>
                                {isDrawing && (
                                    <Form.Group controlId="viewerForm.drawingToolSelection">
                                        <Form.Control
                                            as="select"
                                            onChange={selectDrawingTool}
                                            value={drawingToolSelected}
                                        >
                                            <option key="brush" value="brush">
                                                Brush
                                            </option>
                                            <option
                                                key="area-selector"
                                                value="area-selector"
                                            >
                                                Area selector
                                            </option>
                                        </Form.Control>
                                    </Form.Group>
                                )}
                                {isDrawing && drawingToolSelected === 'brush' && (
                                    <>
                                        Width:
                                        <InputNumber
                                            controls={false}
                                            onChange={handleBrushWidthChange}
                                            min={1}
                                            max={1000}
                                            defaultValue={brushWidth}
                                            value={brushWidth}
                                        />
                                    </>
                                )}
                                <Form.Group controlId="viewerForm.annotationSelection">
                                    <Form.Control
                                        as="select"
                                        onChange={selectAnnotation}
                                        value={String(annotationSelected)}
                                    >
                                        {labelsDict &&
                                            Object.keys(labelsDict).map(
                                                (label, index) => (
                                                    <option
                                                        key={label}
                                                        value={index}
                                                    >
                                                        {label}
                                                    </option>
                                                ),
                                            )}
                                    </Form.Control>
                                </Form.Group>
                            </>
                        )}
                        {isEditing ? (
                            <>
                                <Button
                                    onClick={() => toggleDraw(!isDrawing)}
                                    size="sm"
                                >
                                    {isDrawing
                                        ? 'Pause drawing'
                                        : 'Drawing mode'}
                                </Button>
                                <Button
                                    onClick={this.showResetModal}
                                    size="sm"
                                    variant="danger"
                                >
                                    <PaddedIcon icon="history" />
                                    Reset edit
                                </Button>
                                <Button
                                    disabled={isSaveDisabled}
                                    onClick={this.showSaveModal}
                                    size="sm"
                                    variant="success"
                                >
                                    <PaddedIcon icon="save" />
                                    Save
                                </Button>
                            </>
                        ) : (
                            <>
                                <Button disabled={false} onClick={toggleEdit} size="sm">
                                    <PaddedIcon icon="edit" />
                                    Edit mask
                                </Button>
                                <Button
                                    disabled={
                                        isMaskUpdating ||
                                        !(currentModel && currentModel.name)
                                    }
                                    onClick={this.showRetrainModal}
                                    size="sm"
                                    variant="success"
                                >
                                    <PaddedIcon icon="chalkboard-teacher" />
                                    Retrain current model
                                </Button>
                            </>
                        )}
                    </>
                )}
                {!isEditing && (
                    <>
                        <Form.Group controlId="viewerForm.modelSelection">
                            <Form.Control
                                as="select"
                                disabled={showMask}
                                onChange={selectModel}
                                size="sm"
                                value={modelSelected}
                            >
                                {models.map((model: Model) => (
                                    <option key={model.uuid} value={model.uuid}>
                                        {model.name}
                                    </option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                        <Button
                            disabled={this.props.predictionDisabled}
                            onClick={this.showPredictModal}
                            size="sm"
                            variant="success"
                        >
                            <PaddedIcon icon="comment-medical" />
                            Predict
                        </Button>
                    </>
                )}
                <Button
                    onClick={updateShowMask}
                    size="sm"
                    variant={overlayVisible ? 'warning' : 'success'}
                >
                    {overlayVisible ? (
                        <PaddedIcon icon="image" />
                    ) : (
                        <PaddedIcon icon="images" />
                    )}
                    {overlayVisible ? 'Hide overlay' : 'Show overlay'}
                </Button>
            </Wrapper>
        );
    }
}

Toolbar.contextType = Auth0Context;

export default Toolbar;
