import colormap from 'colormap';

// Compute number of shades in colormap used for annotations
const getNumshades = (labelsDict) =>
    (labelsDict ? Math.max(...Object.values(labelsDict)) * 10 + 1 : 0);

const getColorMap = (nshades) => {
    const colorMap = colormap({
        colormap: 'hsv',
        nshades,
        format: 'rgbaString',
        alpha: 1,
    });
    colorMap[0] = `rgba(255, 255, 255, 1)`;
    return colorMap;
};

export const getCmapFromDict = (labelsDict) => getColorMap(getNumshades(labelsDict));
