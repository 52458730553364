import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Pagination, Table } from 'element-react';

import {
    fetchSlides,
    getPredictionProgress,
} from '../../types/actions/AsyncActionCreators';
import { Slide } from '../../types/data/Slide';
import { MainState } from '../../reducers/MainState';
import { Auth0Context } from '../../infrastructure/Auth0/Auth0Provider';

import { Annotation } from "../../types/data/Annotation";

const CenterFlexWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;
const PaddedIcon = styled(FontAwesomeIcon)`
    padding-right: 4px;
`;
const PaginationWrapper = styled.div`
    display: flex;
    justify-content: center;
`;
const Toolbar = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: 12px;
`;

interface IDispatchProps {
    fetchSlides: (authToken: string, user: string, page: number,
        sorting: { prop: string, order: string }) => void;
    getPredictionProgress: (authToken: string, slideUuids: string[]) => void;
}

interface IPropsFromState {
    slides: Array<Slide>;
    annotations: { [slideUuid: string]: { [annotation_uuid: string]: Annotation } };
    pagination: {
        currentPage: number;
        pageSize: number;
        slidesCount: number;
    };
    predictingSlides: string[];
}

interface IInheritedProps {
    history?: any;
}

interface ISlidesTablePageState {
    sorting: {prop: string, order: string};
}

type ISlidesTablePageProps = IDispatchProps & IPropsFromState & IInheritedProps;

class SlidesTablePage extends Component<
    ISlidesTablePageProps,
    ISlidesTablePageState
> {
    constructor(props: ISlidesTablePageProps) {
        super(props);
        this.state = {
            sorting: {
                prop: '',
                order: '',
            },
        };
    }

    componentDidMount(): void {
        if (this.context.isAuthenticated) {
            this.context.getTokenSilently().then((authToken: string) => {
                this.props.fetchSlides(authToken, this.context.user.sub, 1, this.state.sorting);
            });
        }
    }

    onPageChange = (page: number) => {
        if (this.context.isAuthenticated) {
            this.context.getTokenSilently().then((authToken: string) => {
                this.props.fetchSlides(authToken, this.context.user.sub, page, this.state.sorting);
            });
        }
    };

    handleRowClick = (row: Slide) => {
        this.redirectToSlide(row.uuid);
    };

    handleSortChange = (params: {column: any, prop: string, order: string}) => {
        const { prop, order } = params;
        if (prop !== null && order !== null) {
            this.setState({ sorting: { prop, order } });
        } else {
            this.setState({ sorting: { prop: '', order: '' } });
        }
        if (this.context.isAuthenticated) {
            this.context.getTokenSilently().then((authToken: string) => {
                this.props.fetchSlides(authToken, this.context.user.sub, 1, this.state.sorting);
            });
        }
    };

    redirectToSlide(slide_id: string) {
        const path = `/pannotator/slide/${slide_id}?overlay=true`;
        this.props.history.push(path);
    }

    redirectToUpload() {
        const path = '/pannotator/new';
        this.props.history.push(path);
    }

    render() {
        const { currentPage, pageSize, slidesCount } = this.props.pagination;

        const columns = [
            {
                label: 'Name',
                prop: 'foreign_id',
                resizable: true,
                sortable: true,
            },
            {
                label: 'Scanned by',
                prop: 'scanned_by',
                resizable: true,
            },
            {
                label: 'Scanned date',
                prop: 'scanned_date',
                resizable: true,
                sortable: true,
            },
            {
                label: 'Inserted by',
                prop: 'inserted_by',
                resizable: true,
                sortable: true,
            },
            {
                label: 'Inserted date',
                prop: 'inserted_date',
                resizable: true,
                sortable: true,
            },
            {
                label: 'Annotations',
                prop: 'annotations',
                resizable: true,
                render: (props: any) => (
                    <>
                        <div>
                            {this.props.annotations[props.uuid]
                                ? Object.keys(
                                      this.props.annotations[props.uuid],
                                  ).length
                                : 0}
                        </div>
                        <div>
                            {props.prediction_status &&
                            this.props.predictingSlides.includes(props.uuid) &&
                            Object.keys(props.prediction_status).length > 0
                                ? `Ongoing predictions: ${
                                      Object.values(props.prediction_status)
                                          .length
                                  }`
                                : ''}
                        </div>
                    </>
                ),
            },
            {
                label: 'Thumbnail',
                prop: 'thumbnail',
                resizable: true,
                render: (props: any) =>
                    props.thumbnail && (
                        <CenterFlexWrapper>
                            <img
                                alt="thumbnail"
                                src={`data:image/png;base64,${props.thumbnail}`}
                            />
                        </CenterFlexWrapper>
                    ),
            },
        ];

        return (
            <>
                <Toolbar>
                    <Button
                        onClick={() => this.redirectToUpload()}
                        variant="primary"
                    >
                        <PaddedIcon icon="upload" />
                        Upload new slide
                    </Button>
                </Toolbar>
                <Table
                    {...{
                        emptyText: 'No data',
                        onRowClick: this.handleRowClick,
                        onSortChange: this.handleSortChange,
                    }}
                    style={{ width: '100%' }}
                    columns={columns}
                    data={this.props.slides}
                    border
                />
                <PaginationWrapper>
                    <Pagination
                        currentPage={currentPage}
                        layout="prev, pager, next, jumper, ->, total"
                        onCurrentChange={this.onPageChange}
                        pageSize={pageSize}
                        total={slidesCount}
                    />
                </PaginationWrapper>
            </>
        );
    }
}

const mapStateToProps = (state: MainState) => ({
    slides: Object.values(state.slides),
    annotations: state.annotations,
    pagination: state.pagination.slides,
    predictingSlides: Object.keys(state.predictingSlides),
});

const mapDispatchToProps = (dispatch: any) => ({
    fetchSlides: (authToken: string, user: string, page: number,
        sorting: { prop: string, order: string }) =>
        dispatch(fetchSlides(authToken, user, page, sorting)),
    getPredictionProgress: (authToken: string, slideUuids: string[]) =>
        dispatch(getPredictionProgress(authToken, slideUuids)),
});

SlidesTablePage.contextType = Auth0Context;


export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SlidesTablePage),
);
