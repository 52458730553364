/* eslint-disable react/prefer-stateless-function */
import Drawer from 'rc-drawer';
import React from 'react';
import { Button } from 'element-react';
import styled from 'styled-components';
import { Menu } from 'antd';

import 'rc-drawer/assets/index.css';
import 'antd/dist/antd.css';

import { Annotation } from '../../../types/data/Annotation';
import LimitedWidthText from '../../LimitedWidthText';
import { getCmapFromDict } from '../../../utils/colormaps';
import CreateAnnotationModal from '../../Modal/CreateAnnotationModal';
import { Prediction } from '../../../types/data/Prediction';

const { SubMenu } = Menu;

const LabelsLegend = styled.p`
    display: flex;
    flex-direction: row;
    align-items: baseline;
    height: 100%;
    font-size: 14px;
    padding-right: 2px;
    text-transform: capitalize;
`;
const LegendItem = styled(Menu.Item)`
    border-right: 12px solid ${(props) => props.color};
    border-top: 3px solid ${(props) => props.color};
    border-bottom: 3px solid ${(props) => props.color};
    cursor: ${(props) => (props.disabled ? 'disabled' : 'cursor')};
`;
const DrawerBox = styled.div`
    padding-top: 12px;
`;
const Title = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 16px;
    font-weight: 500;
    padding: 8px;
`;
const InfoLine = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 14px;
    padding: 4px;
`;

type SidebarProps = {
    annotations: Annotation[];
    changeMask: (annotation: Annotation | Prediction, isMaskFromAnnotation: boolean) => void;
    currAnnotationOrSegmentationId?: string,
    isEditing: boolean;
    overlayVisible: boolean;
    segmentation_predictions: Prediction[];
    slideName: string;
};
type SidebarState = {
    isCreateModalOpen: boolean;
};

class Sidebar extends React.Component<SidebarProps, SidebarState> {
    constructor(props: SidebarProps) {
        super(props);
        this.state = {
            isCreateModalOpen: false,
        };
    }

    shouldComponentUpdate(nextProps: SidebarProps, nextState: SidebarState) {
        const {
            annotations,
            currAnnotationOrSegmentationId,
            isEditing,
            overlayVisible,
            slideName,
        } = this.props;
        return (annotations !== nextProps.annotations) ||
            (currAnnotationOrSegmentationId !== nextProps.currAnnotationOrSegmentationId) ||
            (isEditing !== nextProps.isEditing) ||
            (overlayVisible !== nextProps.overlayVisible) ||
            (slideName !== nextProps.slideName) ||
            (this.state.isCreateModalOpen !== nextState.isCreateModalOpen);
    }

    closeCreationModal = () => {
        this.setState({ isCreateModalOpen: false });
    };

    handleClick = (annotation: Annotation | Prediction, isAnnotation: boolean) => {
        if (!this.props.isEditing) {
            this.props.changeMask(annotation, isAnnotation);
        }
    };

    handleCreateAnnotation = () => {
        this.setState({ isCreateModalOpen: true });
    };

    getSubMenu = (
        relevantAnnotationsOrSegmentations: (Annotation|Prediction)[],
        isAnnotations: boolean,
    ) => (
        <SubMenu
            key={isAnnotations ? 'sub1' : 'sub2'}
            disabled={this.props.isEditing}
            title={<span>{isAnnotations ? 'Annotations' : 'Segmentations'}</span>}
        >
            {relevantAnnotationsOrSegmentations.map((item: Annotation | Prediction) => (
                <SubMenu
                    key={item.uuid}
                    style={{
                        backgroundColor:
                        item.uuid === this.props.currAnnotationOrSegmentationId
                                ? '#e9f1f8'
                                : 'white',
                    }}
                    title={item.name}
                >
                    {isAnnotations && <InfoLine>{(item as Annotation).creation_mode}</InfoLine>}
                    {item &&
                        item.mask &&
                        item.mask.label_dictionary &&
                        Object.keys(
                            item.mask.label_dictionary,
                        ).map((label: string) => (
                            <LegendItem
                                color={
                                    // @ts-ignore
                                    getCmapFromDict(
                                        // @ts-ignore
                                        item.mask
                                            .label_dictionary,
                                    )[
                                        // @ts-ignore
                                        item.mask
                                            .label_dictionary[
                                            label
                                        ] * 10
                                    ]
                                }
                                disabled={this.props.isEditing}
                                key={label}
                                onClick={() =>
                                    this.handleClick(item, isAnnotations)
                                }
                            >
                                <LabelsLegend>
                                    {label}
                                </LabelsLegend>
                            </LegendItem>
                        ))}
                </SubMenu>
            ))}
        </SubMenu>
    )

    render() {
        const {
 annotations, isEditing, segmentation_predictions, slideName,
} = this.props;

        const relevantAnns = annotations.filter((ann: Annotation | Prediction) => ann.mask);
        const relevantSegmentations = segmentation_predictions.filter(
            (seg: Annotation | Prediction) => seg.mask,
);

        return (
            <Drawer
                showMask={false}
                level={null}
                placement="left"
                width="320px"
            >
                <CreateAnnotationModal
                    isOpen={this.state.isCreateModalOpen}
                    onRequestClose={this.closeCreationModal}
                    contentLabel="Create annotation"
                />
                <DrawerBox>
                    <Title>
                        <LimitedWidthText maxChars={13} text={slideName} />
                    </Title>
                    <Title>
                        <Button
                            onClick={this.handleCreateAnnotation}
                            size="small"
                            type="success"
                        >
                            Create new annotation
                        </Button>
                    </Title>
                    <Menu
                        defaultSelectedKeys={['1']}
                        defaultOpenKeys={[
                            'sub1',
                            relevantAnns[0] && relevantAnns[0].uuid,
                        ]}
                        mode="inline"
                        selectable={!isEditing}
                    >
                        {this.getSubMenu(relevantAnns, true)}
                        {this.getSubMenu(relevantSegmentations, false)}
                    </Menu>
                </DrawerBox>
            </Drawer>
        );
    }
}

export default Sidebar;
