import React, { Component } from 'react';

import { connect } from 'react-redux';
import {
 Button, Form, Col, Row,
} from 'react-bootstrap';
import styled from 'styled-components';
import { InputNumber } from 'element-react';
import 'element-theme-default';
import { WithContext as ReactTags } from 'react-tag-input';

import { Auth0Context } from '../../infrastructure/Auth0/Auth0Provider';
import './ReactTag.css';
import { uploadModel } from '../../types/actions/AsyncActionCreators';

const ButtonWrapper = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    padding-bottom: 10px;
    padding-right: 10px;
`;
const Wrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 12px;
    border: solid 2px #ccc;
`;
const FormSection = styled.div`
    margin: 1em 0.8em;
`;
const FormSectionHeader = styled.div`
    margin: 0 0 0.8em 0;
    padding: 0.8em 0 0 0.8em;
`;
enum TagField {
    'labels' = 'labels',
}

interface ITag {
    id: string;
    text: string;
}

interface ILabelTag {
    id: string;
    text: string;
    classIndex: number;
}

interface IUploadModelPageProps {
    history?: any;
    uploadModel: (
        authToken: string,
        name: string,
        file: any,
        level: number,
        overlap: number,
        inOutRatio: number,
        numClasses: number,
        labelsDict: any,
        user: string,
    ) => void;
}

interface IUploadModelPageState {
    file: any;
    openslideLevel: number;
    name: string | undefined;
    overlap: number;
    inOutRatio: number;
    labels: ILabelTag[];
}

const KeyCodes = {
    comma: 188,
    enter: 13,
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const toLabelTagList = (tags: string[]) =>
    tags.map((tag, index) =>
({ id: tag, text: tag, classIndex: index }));

const defaultLabels = toLabelTagList([
    'Background',
    'Normal',
    'B2 lesion',
    'B3 Benign changes',
    'High grade DCIS (no necrosis)',
    'Comedo DCIS',
    'Low grade / Intermediate DCIS',
    'Lobular Carcinoma',
    'Invasive Ductal Carcinoma',
    'Uncertain',
    'B4',
]);

class UploadModelPage extends Component<
    IUploadModelPageProps,
    IUploadModelPageState
> {
    constructor(props: IUploadModelPageProps) {
        super(props);
        this.state = {
            file: null,
            name: undefined,
            openslideLevel: 1,
            overlap: 64,
            inOutRatio: 8,
            labels: defaultLabels,
        };
        this.onFormChange = this.onFormChange.bind(this);
    }

    onFormChange(field: string, value: any) {
        if (field === 'file') {
            // Set name to be file name
            this.setState({ name: value.name });
        }
        // @ts-ignore: Field is one of fields
        this.setState({ [field]: value });
    }

    handleSubmit = (e: any) => {
        e.preventDefault();
        const {
            name,
            file,
            openslideLevel,
            overlap,
            inOutRatio,
            labels,
        } = this.state;
        const labelsDict = labels.reduce((acc, curr) => {
            // @ts-ignore
            acc[curr.text.toLowerCase()] = curr.classIndex;
            return acc;
        }, {});
        const numClasses = new Set(Object.values(labelsDict)).size;
        if (
            this.context.isAuthenticated && this.state.file !== null &&
            this.state.name !== undefined
        ) {
            this.context.getTokenSilently().then((authToken: string) => {
                this.props.uploadModel(
                    authToken,
                    String(name),
                    file,
                    openslideLevel,
                    overlap,
                    inOutRatio,
                    numClasses,
                    labelsDict,
                    this.context.user.sub,
                );
                // redirect to models table page
                const path = '/pannotator/models';
                this.props.history.push(path);
            });
        }
    };

    setLabelClass = (id: string, classIndex: number) => {
        this.setState((state: IUploadModelPageState) => {
            const labelIndex = state.labels.findIndex(
                (label: ITag) => label.id === id,
            );
            const { labels } = state;
            labels.splice(labelIndex, 1, {
                ...state.labels[labelIndex],
                classIndex,
            });
            return { labels };
        });
    };

    handleDeleteTag(field: TagField, i: number) {
        // @ts-ignore
        this.setState((state) => ({
            [field]: state[field].filter(
                (tag: ITag, index: number) => index !== i,
            ),
        }));
    }

    handleAddTag(field: TagField, tag: ITag) {
        // @ts-ignore
        this.setState((state: IHomeState) => ({
            [field]: [...state[field], tag],
        }));
    }

    handleDragTag(field: TagField, tag: ITag, currPos: number, newPos: number) {
        const tags = [...this.state[field]];
        const newTags = tags.slice();

        newTags.splice(currPos, 1);
        // @ts-ignore
        newTags.splice(newPos, 0, tag);
        this.setState({ [field]: newTags });
    }

    render() {
        const {
 openslideLevel, overlap, inOutRatio, labels,
} = this.state;
        return (
            <Wrapper>
                <Form>
                    <FormSection>
                        <FormSectionHeader>
                            <Form.Label column={false}>
                                Upload Prediction Model
                            </Form.Label>
                        </FormSectionHeader>
                        <Form.Group as={Row}>
                            <Form.Label md={2} column>
                                Upload File
                            </Form.Label>
                            <Col md={4}>
                                <Form.Control
                                    required
                                    accept=".h5,.hdf5,.zip"
                                    name="file"
                                    type="file"
                                    onChange={(e: any) =>
                                        this.onFormChange(
                                            'file',
                                            e.target.files[0],
                                        )
                                    }
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label md={2} column>
                                Name
                            </Form.Label>
                            <Col md={4}>
                                <Form.Control
                                    required
                                    type="text"
                                    placeholder="Model name"
                                    value={this.state.name}
                                    onChange={(e: any) =>
                                        this.onFormChange(
                                            'name',
                                            e.target.value,
                                        )
                                    }
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label md={2} column>
                                Openslide level
                            </Form.Label>
                            <Col md={4}>
                                <InputNumber
                                    onChange={(value: number) =>
                                        this.onFormChange(
                                            'openslideLevel',
                                            value,
                                        )
                                    }
                                    min={0}
                                    max={2}
                                    defaultValue={openslideLevel}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label md={2} column>
                                Overlap
                            </Form.Label>
                            <Col md={4}>
                                <InputNumber
                                    onChange={(value: number) =>
                                        this.onFormChange('overlap', value)
                                    }
                                    min={0}
                                    max={512}
                                    defaultValue={overlap}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label md={2} column>
                                In/out ratio
                            </Form.Label>
                            <Col md={4}>
                                <InputNumber
                                    onChange={(value: number) =>
                                        this.onFormChange('inOutRatio', value)
                                    }
                                    min={0}
                                    max={8}
                                    defaultValue={inOutRatio}
                                />
                            </Col>
                        </Form.Group>
                        <Form.Group as={Row}>
                            <Form.Label md={2} column>
                                Relevant labels
                            </Form.Label>
                            <Col md={4}>
                                <ReactTags
                                    tags={labels}
                                    suggestions={defaultLabels}
                                    handleDelete={(index: number) =>
                                        this.handleDeleteTag(
                                            TagField.labels,
                                            index,
                                        )
                                    }
                                    handleAddition={(tag: ITag) =>
                                        this.handleAddTag(TagField.labels, tag)
                                    }
                                    handleDrag={(
                                        tag: ITag,
                                        currPos: number,
                                        newPos: number,
                                    ) =>
                                        this.handleDragTag(
                                            TagField.labels,
                                            tag,
                                            currPos,
                                            newPos,
                                        )
                                    }
                                    delimiters={delimiters}
                                />
                            </Col>
                        </Form.Group>
                        {labels.map(({ id, text, classIndex }) => (
                            <Form.Group as={Row} key={id}>
                                <Form.Label md={2} column>
                                    {text}
                                </Form.Label>
                                <Col md={4}>
                                    <InputNumber
                                        onChange={(value: number) =>
                                            this.setLabelClass(id, value)
                                        }
                                        min={0}
                                        max={20}
                                        defaultValue={classIndex}
                                    />
                                </Col>
                            </Form.Group>
                        ))}
                        <ButtonWrapper>
                            <Button
                                disabled={this.state.file === null || this.state.name === undefined}
                                onClick={this.handleSubmit}
                                type="submit"
                                variant="primary"
                            >
                                Upload prediction model
                            </Button>
                        </ButtonWrapper>
                    </FormSection>
                </Form>
            </Wrapper>
        );
    }
}

const mapDispatchToProps = (dispatch: any) =>
({
    uploadModel: (
        authToken: string,
        name: string,
        file: any,
        openslideLevel: number,
        overlap: number,
        inOutRatio: number,
        numClasses: number,
        labelsDict: any,
        user: string,
    ) =>
        dispatch(
            uploadModel(
                authToken,
                name,
                file,
                openslideLevel,
                overlap,
                inOutRatio,
                numClasses,
                labelsDict,
                user,
            ),
        ),
});

UploadModelPage.contextType = Auth0Context;

export default connect(null, mapDispatchToProps)(UploadModelPage);
