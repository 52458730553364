export enum actionTypes {
    'ADD_ALERT' = 'ADD_ALERT',
    'ADD_ANNOTATION' = 'ADD_ANNOTATION',
    'ADD_ANNOTATIONS' = 'ADD_ANNOTATIONS',
    'ADD_ALL_ANNOTATIONS' = 'ADD_ALL_ANNOTATIONS',
    'ADD_ALL_PROFILER_PREDICTIONS' = 'ADD_ALL_PROFILER_PREDICTIONS',
    'ADD_PROGRESS' = 'ADD_PROGRESS',
    'ADD_SEGMENTATIONS' = 'ADD_SEGMENTATIONS',
    'HIDE_PROGRESS' = 'HIDE_PROGRESS',
    'REMOVE_ALERT' = 'REMOVE_ALERT',
    'SET_ANNOTATION_MODES' = 'SET_ANNOTATION_MODES',
    'SET_IS_EDITING' = 'SET_IS_EDITING',
    'SET_MODELS' = 'SET_MODELS',
    'SET_PAGINATION' = 'SET_PAGINATION',
    'SET_PREDICTION_CLASS_COUNTS' = 'SET_PREDICTION_CLASS_COUNTS',
    'SET_SLIDE' = 'SET_SLIDE',
    'SET_SLIDES' = 'SET_SLIDES',
    'SET_SLIDE_UPDATED_TIME' = 'SET_SLIDE_UPDATED_TIME',
    'SET_SLIDE_STATUS' = 'SET_SLIDE_STATUS',
    'SET_TISSUE_RATIO' = 'SET_TISSUE_RATIO',
    'REGISTER_PREDICTION' = 'REGISTER_PREDICTION',
    'UPDATE_PREDICTION_PROGRESS' = 'UPDATE_PREDICTION_PROGRESS',
}
