import paper from 'paper';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import styled from 'styled-components';
import queryString from 'query-string';
import ClipLoader from 'react-spinners/ClipLoader';

import { API_BASE, SEGMENTATION_TASK_TYPES } from '../../../constants';
import OpenSeadragon from '../../../plugins/openseadragon-paperjs-overlay';
import '../../../plugins/openseadragon-scalebar';
import { MainState } from '../../../reducers/MainState';
import { setSlideStatus } from '../../../types/actions/ActionCreators';
import {
    createEditingMask,
    fetchModels,
    predict,
    resetMask,
    saveMask,
    syncSlide,
    updateAnnotations,
    updateModel,
} from '../../../types/actions/AsyncActionCreators';
import { OpenseadragonWrapper } from '../../OpenseadragonWrapper';
import { getCmapFromDict } from '../../../utils/colormaps';
import Sidebar from './Sidebar';
import Toolbar from './Toolbar';
import PredictionIndicator from "./PredictionIndicator";
import LimitedWidthText from '../../LimitedWidthText';
import { Annotation } from '../../../types/data/Annotation';
import { Model } from '../../../types/data/Model';
import { Auth0Context } from '../../../infrastructure/Auth0/Auth0Provider';
import { Slide, SYNC_STATUS } from '../../../types/data/Slide';
import { Prediction } from '../../../types/data/Prediction';

const getViewer = (authToken: string) =>
    new OpenSeadragon.Viewer({
        id: 'osd-viewer',
        prefixUrl: '//openseadragon.github.io/openseadragon/images/',
        gestureSettingsMouse: {
            clickToZoom: false,
            dblClickToZoom: true,
        },
        visibilityRatio: 1.0,
        constrainDuringPan: true,
        imageLoaderLimit: 5,
        showNavigator: true,
        navigatorPosition: 'BOTTOM_LEFT',
        navigatorHeight: 140,
        navigatorWidth: 220,
        navigatorBackground: '#e9f1f8',
        navigatorBorderColor: 'white',
        navigatorDisplayRegionColor: '#28a745',
        showZoomControl: false,
        showHomeControl: false,
        showFullPageControl: false,
        zoomPerScroll: 1.4,
        loadTilesWithAjax: true,
        // @ts-ignore
        ajaxHeaders: {
            authorization: `Bearer ${authToken}`,
        },
    });

const updateQueryParams = (newParams: any) => {
    const oldParams = queryString.parse(window.location.search);
    const queryParams = { ...oldParams, ...newParams };
    const search = queryString.stringify(queryParams);
    const newUrl = `${window.location.origin}${window.location.pathname}?${search}`;
    window.history.pushState({ path: newUrl }, '', newUrl);
};

const getTiledImage = (slideUuid: string, authToken: string, onSuccess: (() => void)) => ({
    tileSource: `${API_BASE}/histoslide/${slideUuid}.dzi`,
    x: 0,
    y: 0,
    ajaxHeaders: {
        authorization: `Bearer ${authToken}`,
    },
    success: () => {
        onSuccess();
    },
});

const getColor = (colorMap: any, colorSelected: number) => new paper.Color(
    colorMap[colorSelected * 10],
);

const strToBool = (str?: string) => str === 'true';

const vecToNorm = (x: number, y: number) => Math.sqrt(x ** 2 + y ** 2);

const LoaderContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
`;
const ViewerContainer = styled.div`
    height: 100%;
    width: 100%;
    margin-bottom: auto;
    margin-left: auto;
    margin-right: auto;
    margin-top: 0;
`;
const SlideInfo = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 240px;
    position: absolute;
    left: 8px;
    top: 16px;
    z-index: 1;
    font-weight: 500;
    font-size: 16px;
    background-color: #e9f1f8;
    border-radius: 3px;
    border: 1px solid #d9e1e8;
`;

const originalOverlayState = {
    firstPoint: null,
    path: null,
};

interface IDispatchProps {
    createEditingMask: (authToken: string, maskUuid: string, user: string) => void;
    fetchModels: (authToken: string) => void;
    predict: (authToken: string, modelId: string, user: string) => void;
    resetMask: (authToken: string, maskUuid: string, user: string) => void;
    saveMask: (authToken: string, maskUuid: string, modelUuid: string, user: string) => void;
    setSlideStatus: (status: SYNC_STATUS) => void;
    syncSlide: (authToken: string, modelUuids: string[], user: string) => void;
    updateAnnotations: (authToken: string, maskUuid: string, polygons: any, tool: string,
        thickness: number, user: string) => void;
    updateModel: (authToken: string, maskUuid: string, modelUuid: string, user: string) => void;
}

interface IInheritedProps {
    annotations: Annotation[];
    history?: any;
    location?: any;
    match?: any;
    model: any;
    models: { [model_uuid: string]: Model };
    segmentation_predictions: Prediction[];
    slide?: Slide;
    slideUpdatedTime: string;
}

type ISlidePageProps = IInheritedProps & IDispatchProps


interface ISlidePageState {
    annotationOrSegmentation?: Annotation | Prediction;
    annotationSelected: number;
    brushWidth: number;
    colorMap: any;
    colorSelected: number;
    drawingToolSelected: string;
    firstPoint: any;
    isDrawing: boolean;
    isEditing: boolean;
    isEditingAllowed: boolean;
    isMaskUpdating: boolean;
    isPostEditReloading: boolean;
    isOsdPrepared: boolean;
    labelsDict?: { [label: string]: number };
    maskUuid?: string;
    mouseCircle: any;
    modelSelected?: string;
    opacity: number | number[];
    overlayVisible: boolean;
    path: any;
    showMask: boolean;
    zoom: number;
}

class SlidePage extends Component<ISlidePageProps, ISlidePageState> {
    viewer: any;

    overlay: any;

    constructor(props: ISlidePageProps) {
        super(props);
        this.state = {
            annotationOrSegmentation: undefined,
            annotationSelected: 0,
            brushWidth: 50,
            colorMap: null,
            colorSelected: 0,
            drawingToolSelected: 'brush',
            maskUuid: undefined,
            modelSelected: undefined,
            mouseCircle: null,
            isDrawing: true,
            isEditing: false,
            isEditingAllowed: true,
            isMaskUpdating: false,
            isOsdPrepared: false,
            isPostEditReloading: false,
            labelsDict: undefined,
            opacity: 0.4,
            overlayVisible: false,
            showMask: false,
            zoom: 1,
            ...originalOverlayState,
        };
    }

    componentDidMount(): void {
        if (this.props.model) {
            this.setState({ modelSelected: this.props.model.uuid });
        }
        if (this.context.isAuthenticated) {
            this.context.getTokenSilently().then((authToken: string) => {
                this.props.syncSlide(
                    authToken,
                    this.state.modelSelected ? [this.state.modelSelected] : [],
                    this.context.user.sub,
                );
            });
        }
    }

    componentDidUpdate(
        prevProps: ISlidePageProps,
        prevState: ISlidePageState,
    ): void {
        const { slide, annotations } = this.props;
        if (slide && slide.sync_status === SYNC_STATUS.ERROR) {
            const path = '/pannotator';
            this.props.history.push(path);
        }

        if (
            slide &&
            slide.sync_status === SYNC_STATUS.SYNCED &&
            !this.state.isOsdPrepared &&
            annotations && slide.updated
        ) {
            const relevantAnnotations = annotations.filter((ann) => ann.mask);
            if (relevantAnnotations && relevantAnnotations.length > 0) {
                this.setState({
                    annotationOrSegmentation: relevantAnnotations[0],
                    maskUuid:
                        relevantAnnotations[0] &&
                        relevantAnnotations[0].mask &&
                        relevantAnnotations[0].mask.uuid,
                });
            }
            this.props.setSlideStatus(SYNC_STATUS.RENDERING);
            this.setState({ isOsdPrepared: true });
            this.prepareOsd();
        }
        if (
            prevProps.annotations &&
            annotations &&
            annotations.length - prevProps.annotations.length === 1
        ) {
            const lastAnn = annotations[annotations.length - 1];
            if (lastAnn.mask) {
                this.setState({
                    annotationOrSegmentation: lastAnn,
                    // @ts-ignore new annotations should always have a mask
                    maskUuid: lastAnn.mask.uuid,
                });
            } else {
                console.log('Got new annotation without a mask', lastAnn);
            }
        }
        if (prevProps.slide && slide && prevProps.slide.isEditing !== slide.isEditing) {
            updateQueryParams({ isEditing: !!slide.isEditing });
            this.setState({ isEditing: !!slide.isEditing });
            if (prevProps.slide.isEditing && !slide.isEditing) {
                this.reloadMask();
            }
        }

        if (prevProps.slideUpdatedTime !== this.props.slideUpdatedTime) {
            this.setState(originalOverlayState);
            // @ts-ignore
            paper.project.activeLayer.removeChildren();
            this.reloadMask();
            updateQueryParams({ isDrawing: true });
            this.setState({ isDrawing: true });
        }
        if (prevProps.model !== this.props.model) {
            this.setState({ modelSelected: this.props.model.uuid });
        }
        if (prevState.annotationOrSegmentation !== this.state.annotationOrSegmentation) {
            let labelsDict =
                this.state.annotationOrSegmentation &&
                this.state.annotationOrSegmentation.mask &&
                this.state.annotationOrSegmentation.mask.label_dictionary;

            if (labelsDict) {
                const selectedLabel = Object.keys(labelsDict)[0];
                this.setState({
                    annotationSelected: 0,
                    colorSelected: labelsDict[selectedLabel],
                });
                if (
                    !Object.keys(labelsDict)
                        .map((key) => key.toLowerCase())
                        .includes('background')
                ) {
                    labelsDict = { background: 0, ...labelsDict };
                }
                this.setState({ labelsDict });
                this.setState({ colorMap: getCmapFromDict(labelsDict) });
            }
        }
        if (
            prevState.maskUuid &&
            this.state.maskUuid &&
            prevState.maskUuid !== this.state.maskUuid
        ) {
            if (this.state.isPostEditReloading) {
                this.setState({ isPostEditReloading: false });
            } else {
                this.reloadMask();
            }
        }
        if (!prevState.showMask && this.state.showMask) {
            const { isDrawing, isEditing } = queryString.parse(
                window.location.search,
            );
            this.setState({
                modelSelected: this.props.model && this.props.model.uuid,
                isDrawing: strToBool(String(isDrawing)),
                isEditing: strToBool(String(isEditing)),
            });
            if (this.state.annotationOrSegmentation && this.state.annotationOrSegmentation.mask) {
                this.reloadMask();
            }
            document.addEventListener('keydown', this.onKeyDown);
        } else if (prevState.showMask && !this.state.showMask) {
            this.toggleDraw(false);
            this.setState({ overlayVisible: false });
            const mask = this.viewer.world.getItemAt(1);
            if (mask !== undefined) {
                this.viewer.world.removeItem(mask);
            }
            document.removeEventListener('keydown', this.onKeyDown);
            // @ts-ignore
            paper.project.activeLayer.removeChildren();
        }
        if (prevState.isDrawing && !this.state.isDrawing) {
            // Reset mouse circle to ensure a new one is created when conditions are met
            this.setState({ mouseCircle: null });
        }
        if ((prevState.colorSelected !== this.state.colorSelected) &&
            (this.state.mouseCircle !== null)) {
            this.setState(({ mouseCircle, colorMap, colorSelected }) => {
                const circle = mouseCircle;
                circle.fillColor = getColor(colorMap, colorSelected);
                return { mouseCircle: circle };
            })
        }
        if (this.state.mouseCircle !== null &&
            ((prevState.zoom !== this.state.zoom) ||
            (prevState.brushWidth !== this.state.brushWidth))) {
            this.setState(({ brushWidth, mouseCircle, zoom }) => {
                const radius = Math.round((15 * brushWidth) / zoom);
                const circle = mouseCircle;
                circle.scale(radius / (circle.bounds.width / 2));
                return { mouseCircle: circle };
            })
        }
    }

    componentWillUnmount() {
        if (this.viewer) {
            this.viewer.removeAllHandlers('zoom');
            this.viewer.world.resetItems();
        }
        if (this.state.isEditing && this.context.isAuthenticated) {
            this.context.getTokenSilently().then((authToken: string) => {
                const { maskUuid } = this.state;
                if (maskUuid) {
                    this.props.resetMask(
                        authToken,
                        maskUuid,
                        this.context.user.sub,
                    );
                }
            });
        }
    }

    prepareOsd = () => {
        if (this.context.isAuthenticated) {
            this.context.getTokenSilently().then((authToken: string) => {
                if (Object.keys(this.props.models).length === 0) {
                    this.props.fetchModels(authToken);
                }
                this.viewer = getViewer(authToken);
                if (this.props.slide && this.props.slide.mpp) {
                    this.viewer.scalebar({
                        minWidth: '75px',
                        pixelsPerMeter: 1e6 / this.props.slide.mpp,
                        color: 'black',
                        location: 3,
                        yOffset: 100,
                        xOffset: 10,
                      });
                }

                this.viewer.addHandler('zoom', this.handleZoom);

                new OpenSeadragon.MouseTracker({
                    element: this.viewer.canvas,
                    pressHandler: this.pressHandler,
                    dragHandler: this.dragHandler,
                    dragEndHandler: this.dragEndHandler,
                    moveHandler: this.moveHandler,
                }).setTracking(true);
                this.props.setSlideStatus(SYNC_STATUS.DISPLAYED);
                this.overlay = this.viewer.paperjsOverlay();
                const { labelsDict } = this.state;
                if (labelsDict !== undefined) {
                    this.setState({ colorMap: getCmapFromDict(labelsDict) });
                }

                const { overlay } = queryString.parse(window.location.search);
                const onSuccess = () => {
                    if (strToBool(String(overlay)) && !this.state.showMask) {
                        this.updateShowMask();
                    }
                };
                const tiledImageOptions = getTiledImage(
                    this.props.match.params.slideUuid,
                    authToken,
                    onSuccess,
                );

                this.viewer.addTiledImage(tiledImageOptions);
            });
        }
    };

    dragHandler = (event: any) => {
        const {
            isEditing, isDrawing, isMaskUpdating, firstPoint,
        } = this.state;
        if (isEditing && isDrawing && !isMaskUpdating && firstPoint) {
            const transformed = paper.view.viewToProject(
                new paper.Point(event.position.x, event.position.y),
            );

            const transformedPoint = new paper.Point(
                this.getBoundedCoord(transformed.x, 'x'),
                this.getBoundedCoord(transformed.y, 'y'),
            );

            const deltaX = transformedPoint.x - this.state.firstPoint.x;
            const deltaY = transformedPoint.y - this.state.firstPoint.y;
            const distanceMoved = vecToNorm(deltaX, deltaY);

            if (distanceMoved > 1) {
                this.setState((prevState) => {
                    const newPath = prevState.path;
                    if (prevState.drawingToolSelected === 'area-selector') {
                        newPath.add(transformedPoint);
                    } else {
                        newPath.add(transformedPoint);
                    }
                    return {
                        path: newPath,
                        firstPoint: transformedPoint,
                    };
                });
            }
        }
    };
    // eslint-disable-next-line
    dragEndHandler = (event: any) => {
        const {
            isEditing,
            isDrawing,
            isMaskUpdating,
            firstPoint,
            maskUuid,
            drawingToolSelected,
            brushWidth,
            zoom,
        } = this.state;
        if (isEditing && isDrawing && !isMaskUpdating && firstPoint) {
            const points = this.state.path.segments.map((s: any) => [
                s.point.x,
                s.point.y,
            ]);
            updateQueryParams({ isDrawing: false });
            this.setState({ isDrawing: false });
            if (maskUuid) {
                if (this.context.isAuthenticated) {
                    this.context
                        .getTokenSilently()
                        .then((authToken: string) => {
                            this.props.updateAnnotations(
                                authToken,
                                maskUuid,
                                [{ points, label: this.state.colorSelected }],
                                drawingToolSelected,
                                Math.round(brushWidth / zoom),
                                this.context.user.sub,
                            );
                        });
                }
            }
        }
    };

    moveHandler = (event: any) => {
        const {
 brushWidth, drawingToolSelected, isDrawing, isEditing, showMask, zoom,
} = this.state;
        // eslint-disable-next-line
        if (drawingToolSelected === 'brush' && showMask && isEditing && isDrawing && (this.viewer.world._items.length > 1)) {
            const point = paper.view.viewToProject(
                new paper.Point(event.position.x, event.position.y),
            );
            this.setState(({ colorMap, colorSelected, mouseCircle }) => {
                let circle;
                const radius = Math.round((15 * brushWidth) / zoom);
                if (mouseCircle === null) {
                    circle = new paper.Path.Circle(point, radius);
                    circle.fillColor = getColor(colorMap, colorSelected);
                } else {
                    circle = mouseCircle;
                    circle.position.x = point.x;
                    circle.position.y = point.y;
                }
                return { mouseCircle: circle }
            });
        }
    };

    pressHandler = (event: any) => {
        const { isEditing, isDrawing, isMaskUpdating } = this.state;
        if (isEditing && isDrawing && !isMaskUpdating) {
            const transformedPoint = paper.view.viewToProject(
                new paper.Point(event.position.x, event.position.y),
            );
            const newPath = new paper.Path();
            newPath.strokeColor = new paper.Color(0, 0, 0);
            newPath.strokeJoin = 'round';
            if (this.state.drawingToolSelected === 'brush') {
                newPath.strokeWidth = Math.round((30 * this.state.brushWidth) / this.state.zoom);
                newPath.strokeCap = 'round';
                newPath.closed = false;
            } else {
                newPath.strokeWidth = 4;
                newPath.closed = true;
                newPath.fillRule = 'evenodd';
            }
            this.setState((prevState: ISlidePageState) => {
                const pathColor = getColor(prevState.colorMap, prevState.colorSelected);
                if (prevState.drawingToolSelected !== 'brush') {
                    newPath.fillColor = pathColor;
                }
                newPath.strokeColor = pathColor;
                return { firstPoint: transformedPoint, path: newPath };
            });
        }
    };

    /* Pass isMaskFromAnnotation to disable editing on predicted masks */
    changeMask = (
        annotationOrSegmentation: Annotation | Prediction,
        isMaskFromAnnotation: boolean,
    ) => {
        this.setState({
            annotationOrSegmentation,
            isEditingAllowed: isMaskFromAnnotation,
            maskUuid: annotationOrSegmentation.mask && annotationOrSegmentation.mask.uuid,
        });
    };

    getBoundedCoord = (freeCoord: number, axis: string) => {
        // @ts-ignore
        if (freeCoord < 0) {
            return 0;
        }
        if (freeCoord > this.viewer.world.getItemAt(0).getContentSize()[axis]) {
            return this.viewer.world.getItemAt(0).getContentSize()[axis] - 100;
        }
        return freeCoord;
    };

    handleBrushWidthChange = (brushWidth: number) => {
        this.setState({ brushWidth });
    };

    handleOpacityChange = (e: any, newValue: number | number[]) => {
        // eslint-disable-next-line no-underscore-dangle
        if (this.viewer.world._items.length > 1) {
            this.viewer.world.getItemAt(1).setOpacity(newValue);
        }
        this.setState({ opacity: newValue });
    };

    handleZoom = (e: any) => {
        this.setState({ zoom: e.zoom });
    };

    onKeyDown = (event: any) => {
        // isDrawing toggling
        if (event.shiftKey) {
            this.toggleDraw(!this.state.isDrawing);
        }
        // Change annotation
        const keyCode = event.code;
        if (keyCode.startsWith('Digit')) {
            const labelIndex = Number(keyCode[5]);
            const { labelsDict } = this.state;
            if (labelsDict) {
                const labels = Object.values(labelsDict);
                if (labelIndex < labels.length) {
                    const colorSelected = Number(labels[labelIndex]);
                    this.setState({
                        annotationSelected: labelIndex,
                        colorSelected,
                    });
                }
            }
        }
        if (this.state.drawingToolSelected === 'brush') {
            if (event.code === 'BracketLeft' && this.state.brushWidth > 1) {
                this.setState(({ brushWidth }) => ({ brushWidth: brushWidth - 1 }));
            } else if (event.code === 'BracketRight' && this.state.brushWidth < 1000) {
                this.setState(({ brushWidth }) => ({ brushWidth: brushWidth + 1 }));
            }
        }
    };

    reloadMask = () => {
        const oldMask = this.viewer && this.viewer.world.getItemAt(1);
        if (this.context.isAuthenticated) {
            this.context.getTokenSilently().then((authToken: string) => {
                let tiledImageOptions = {
                    tileSource: `${API_BASE}/histoslide/${
                        this.state.maskUuid
                    }/${String(new Date().getTime())}/mask.dzi`,
                    opacity: 0.4,
                    width: undefined,
                    x: 0,
                    y: 0,
                    ajaxHeaders: {
                        authorization: `Bearer ${authToken}`,
                    },
                    error: () => {
                        updateQueryParams({ overlay: false });
                        this.setState({ showMask: false });
                    },
                    success: () => {
                        this.setState({ overlayVisible: true });
                    },
                };
                if (oldMask !== undefined) {
                    const { width, x, y } = oldMask.getBounds();
                    tiledImageOptions = {
                        ...tiledImageOptions,
                        width,
                        x,
                        y,
                    };
                    this.viewer.world.removeItem(oldMask);
                }

                if (this.state.showMask) {
                    this.viewer.addTiledImage(tiledImageOptions);
                }
            });
        }
    };

    resetEdit = () => {
        this.toggleDraw(false);
        if (this.context.isAuthenticated) {
            this.context.getTokenSilently().then((authToken: string) => {
                const { maskUuid } = this.state;
                if (maskUuid) {
                    this.props.resetMask(
                        authToken,
                        maskUuid,
                        this.context.user.sub,
                    );
                }
            });
        }
        updateQueryParams({ isEditing: false });
        this.setState({ ...originalOverlayState, isEditing: false });
    };

    saveEdit = () => {
        if (this.context.isAuthenticated) {
            this.context.getTokenSilently().then((authToken: string) => {
                const { maskUuid } = this.state;
                if (maskUuid) {
                    this.setState({
                        ...originalOverlayState,
                        isEditing: false,
                        isPostEditReloading: true,
                    });
                    this.props.saveMask(
                        authToken,
                        maskUuid,
                        (this.state.annotationOrSegmentation &&
                            this.state.annotationOrSegmentation.model_uuid) ||
                            '',
                        this.context.user.sub,
                    );
                }
            });
        }
    };

    selectAnnotation = (e: any) => {
        const { labelsDict } = this.state;
        if (labelsDict) {
            const colorSelected = Number(
                Object.values(labelsDict)[Number(e.target.value)],
            );
            this.setState({
                annotationSelected: Number(e.target.value),
                colorSelected,
            });
        }
    };

    selectDrawingTool = (e: any) => {
        this.setState({ drawingToolSelected: e.target.value });
    };

    selectModel = (e: any) => {
        this.setState({
            modelSelected: e.target.value,
        });
    };

    toggleDraw = (isDrawing: boolean) => {
        this.viewer.panHorizontal = !isDrawing;
        this.viewer.panVertical = !isDrawing;

        updateQueryParams({ isDrawing });
        this.setState({ isDrawing });
    };

    toggleEdit = () => {
        if (!this.state.isEditing) {
            this.toggleDraw(true);
            if (this.context.isAuthenticated) {
                this.context.getTokenSilently().then((authToken: string) => {
                    const { maskUuid } = this.state;
                    if (maskUuid) {
                        this.props.createEditingMask(
                            authToken,
                            maskUuid,
                            this.context.user.sub,
                        );
                    }
                });
            }
        }
    };

    updateShowMask = (e?: any) => {
        if (e) {
            e.preventDefault();
        }
        if (this.viewer && this.state.overlayVisible === this.state.showMask) {
            updateQueryParams({ overlay: !this.state.showMask });
            this.setState((prevState: ISlidePageState) => ({
                showMask: !prevState.showMask,
            }));
        }
    };

    render() {
        const {
            changeMask,
            handleBrushWidthChange,
            handleOpacityChange,
            resetEdit,
            saveEdit,
            selectAnnotation,
            selectDrawingTool,
            selectModel,
            toggleDraw,
            toggleEdit,
            updateShowMask,
        } = this;

        const {
            annotations, model, models, segmentation_predictions, slide,
        } = this.props;

        const {
            annotationOrSegmentation,
            annotationSelected,
            brushWidth,
            drawingToolSelected,
            isDrawing,
            isEditing,
            isEditingAllowed,
            isMaskUpdating,
            labelsDict,
            maskUuid,
            modelSelected,
            opacity,
            overlayVisible,
            showMask,
        } = this.state;

        const toolbarProps = {
            annotationSelected,
            brushWidth,
            drawingToolSelected,
            handleBrushWidthChange,
            handleOpacityChange,
            isDrawing,
            isEditing,
            isEditingAllowed,
            isMaskUpdating,
            isSaveDisabled: this.state.path !== null,
            labelsDict,
            currentModel: model,
            maskUuid,
            models: Object.values(models),
            modelSelected,
            opacity,
            overlayVisible,
            predict: this.props.predict,
            predictionDisabled:
                !this.state.modelSelected ||
                (this.props.slide &&
                    this.props.slide.prediction_status &&
                    this.props.slide.prediction_status[
                        this.state.modelSelected
                    ]),
            resetEdit,
            showMask,
            saveEdit,
            selectAnnotation,
            selectDrawingTool,
            selectModel,
            toggleDraw,
            toggleEdit,
            updateModel: this.props.updateModel,
            updateShowMask,
        };

        const slideName = slide && slide.foreign_id ? slide.foreign_id : 'No slide';

        const sidebarProps = {
            annotations,
            changeMask,
            currAnnotationOrSegmentationId: annotationOrSegmentation
                && annotationOrSegmentation.uuid,
            isEditing,
            overlayVisible,
            segmentation_predictions,
            slideName,
        };

        return this.props.slide && this.props.slide.updated && this.props.annotations ? (
            <>
                <SlideInfo>
                    <LimitedWidthText maxChars={23} text={slideName} />
                </SlideInfo>
                <Sidebar {...sidebarProps} />
                <Toolbar {...toolbarProps} />
                {slide && slide.prediction_status &&
                    Object.keys(slide.prediction_status).length > 0 && (
                        <PredictionIndicator
                            predictions={slide.prediction_status}
                            models={models}
                        />
                    )}
                <ViewerContainer>
                    <OpenseadragonWrapper />
                </ViewerContainer>
            </>
        ) : (
            <LoaderContainer>
                <ClipLoader size={150} loading />
            </LoaderContainer>
        );
    }
}

const mapStateToProps = (state: MainState, ownProps: any) => {
    const segmentationModels: {[modelUuid: string]: Model} = {}
    for (const modelUuid of Object.keys(state.models)) {
        const model = state.models[modelUuid];
        if (SEGMENTATION_TASK_TYPES.includes(model.type)) {
            segmentationModels[modelUuid] = model;
        }
    }

    return {
        annotations: state.annotations[ownProps.match.params.slideUuid]
            ? Object.values(state.annotations[ownProps.match.params.slideUuid])
                .sort((a, b) => (a.creation_date < b.creation_date ? -1 : 1))
            : [],
        model: Object.values(state.models).filter(
            (m) => SEGMENTATION_TASK_TYPES.includes(m.type),
        )[0],
        models: segmentationModels,
        segmentation_predictions: state.segmentation_predictions[ownProps.match.params.slideUuid]
        ? Object.values(state.segmentation_predictions[ownProps.match.params.slideUuid])
            .sort((a, b) => (a.creation_date < b.creation_date ? -1 : 1))
            .filter((s) => s.model_uuid !== null)
        : [],
        slideUpdatedTime:
            state.slidesUpdatedTime[ownProps.match.params.slideUuid],
        slide: state.slide,
    }
};

const mapDispatchToProps = (dispatch: any, ownProps: any) => ({
    createEditingMask: (authToken: string, maskUuid: string, user: string) =>
        dispatch(
            createEditingMask(
                authToken,
                ownProps.match.params.slideUuid,
                maskUuid,
                user,
            ),
        ),
    fetchModels: (authToken: string) => dispatch(fetchModels(authToken)),
    predict: (authToken: string, modelId: string, user: string) =>
        dispatch(
            predict(authToken, ownProps.match.params.slideUuid, modelId, user),
        ),
    resetMask: (authToken: string, maskUuid: string, user: string) =>
        dispatch(
            resetMask(
                authToken,
                ownProps.match.params.slideUuid,
                maskUuid,
                user,
            ),
        ),
    saveMask: (
        authToken: string,
        maskUuid: string,
        modelUuid: string,
        user: string,
    ) =>
        dispatch(
            saveMask(
                authToken,
                ownProps.match.params.slideUuid,
                maskUuid,
                modelUuid,
                user,
            ),
        ),
    setSlideStatus: (status: SYNC_STATUS) =>
        dispatch(setSlideStatus(ownProps.match.params.slideUuid, status)),
    syncSlide: (authToken: string, modelUuids: string[], user: string) =>
        dispatch(
            syncSlide(
                authToken,
                ownProps.match.params.slideUuid,
                modelUuids,
                user,
            ),
        ),
    updateAnnotations: (
        authToken: string,
        maskUuid: string,
        polygons: any,
        tool: string,
        thickness: number,
        user: string,
    ) =>
        dispatch(
            updateAnnotations(
                authToken,
                ownProps.match.params.slideUuid,
                maskUuid,
                polygons,
                tool,
                thickness,
                user,
            ),
        ),
    updateModel: (
        authToken: string,
        maskUuid: string,
        modelUuid: string,
        user: string,
    ) =>
        dispatch(
            updateModel(
                authToken,
                ownProps.match.params.slideUuid,
                maskUuid,
                modelUuid,
                user,
            ),
        ),
});

SlidePage.contextType = Auth0Context;

export default withRouter(
    connect(mapStateToProps, mapDispatchToProps)(SlidePage),
);
